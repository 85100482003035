export const election2020CandidatesArkhangai = [
 {
  "id": 1,
  "Жамъянгийн МӨНХБАТ - МАН ": 435,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 552,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 423,
  "Нямжавын БАТБАЯР - АН ": 135,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 106,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 218,
  "Нацагийн УДВАЛ - ТБЭ ": 81,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 120,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 67,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 90,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 21,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 28,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 7,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 18,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 14,
  "Ранжилын БАДМААНЯМБУУ ": 53,
  "Энэбишийн БОЛОРЧУЛУУН ": 124,
  "Батбаатарын ГАНПҮРЭВ ": 73,
  "Энхбаярын МӨНХСАЙХАН ": 222
 },
 {
  "id": 2,
  "Жамъянгийн МӨНХБАТ - МАН ": 410,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 594,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 388,
  "Нямжавын БАТБАЯР - АН ": 169,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 137,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 239,
  "Нацагийн УДВАЛ - ТБЭ ": 135,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 179,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 88,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 118,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 28,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 19,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 8,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 13,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 14,
  "Ранжилын БАДМААНЯМБУУ ": 81,
  "Энэбишийн БОЛОРЧУЛУУН ": 121,
  "Батбаатарын ГАНПҮРЭВ ": 102,
  "Энхбаярын МӨНХСАЙХАН ": 232
 },
 {
  "id": 3,
  "Жамъянгийн МӨНХБАТ - МАН ": 380,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 538,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 355,
  "Нямжавын БАТБАЯР - АН ": 168,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 145,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 278,
  "Нацагийн УДВАЛ - ТБЭ ": 104,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 153,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 73,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 93,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 23,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 21,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 3,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 8,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 63,
  "Энэбишийн БОЛОРЧУЛУУН ": 136,
  "Батбаатарын ГАНПҮРЭВ ": 76,
  "Энхбаярын МӨНХСАЙХАН ": 175
 },
 {
  "id": 4,
  "Жамъянгийн МӨНХБАТ - МАН ": 384,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 468,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 360,
  "Нямжавын БАТБАЯР - АН ": 177,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 149,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 243,
  "Нацагийн УДВАЛ - ТБЭ ": 58,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 77,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 42,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 106,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 14,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 35,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 11,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 5,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 6,
  "Ранжилын БАДМААНЯМБУУ ": 64,
  "Энэбишийн БОЛОРЧУЛУУН ": 139,
  "Батбаатарын ГАНПҮРЭВ ": 45,
  "Энхбаярын МӨНХСАЙХАН ": 173
 },
 {
  "id": 5,
  "Жамъянгийн МӨНХБАТ - МАН ": 295,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 427,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 275,
  "Нямжавын БАТБАЯР - АН ": 160,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 143,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 269,
  "Нацагийн УДВАЛ - ТБЭ ": 71,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 111,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 58,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 75,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 15,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 13,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 4,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 12,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 49,
  "Энэбишийн БОЛОРЧУЛУУН ": 133,
  "Батбаатарын ГАНПҮРЭВ ": 66,
  "Энхбаярын МӨНХСАЙХАН ": 168
 },
 {
  "id": 6,
  "Жамъянгийн МӨНХБАТ - МАН ": 307,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 403,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 299,
  "Нямжавын БАТБАЯР - АН ": 127,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 110,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 197,
  "Нацагийн УДВАЛ - ТБЭ ": 91,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 133,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 69,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 80,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 13,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 14,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 6,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 43,
  "Энэбишийн БОЛОРЧУЛУУН ": 124,
  "Батбаатарын ГАНПҮРЭВ ": 47,
  "Энхбаярын МӨНХСАЙХАН ": 123
 },
 {
  "id": 7,
  "Жамъянгийн МӨНХБАТ - МАН ": 303,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 425,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 281,
  "Нямжавын БАТБАЯР - АН ": 145,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 117,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 249,
  "Нацагийн УДВАЛ - ТБЭ ": 71,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 135,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 64,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 77,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 26,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 28,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 6,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 13,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 12,
  "Ранжилын БАДМААНЯМБУУ ": 31,
  "Энэбишийн БОЛОРЧУЛУУН ": 160,
  "Батбаатарын ГАНПҮРЭВ ": 56,
  "Энхбаярын МӨНХСАЙХАН ": 159
 },
 {
  "id": 8,
  "Жамъянгийн МӨНХБАТ - МАН ": 376,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 499,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 338,
  "Нямжавын БАТБАЯР - АН ": 171,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 145,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 277,
  "Нацагийн УДВАЛ - ТБЭ ": 112,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 173,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 89,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 70,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 21,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 21,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 5,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 53,
  "Энэбишийн БОЛОРЧУЛУУН ": 120,
  "Батбаатарын ГАНПҮРЭВ ": 83,
  "Энхбаярын МӨНХСАЙХАН ": 171
 },
 {
  "id": 9,
  "Жамъянгийн МӨНХБАТ - МАН ": 507,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 652,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 434,
  "Нямжавын БАТБАЯР - АН ": 264,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 220,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 378,
  "Нацагийн УДВАЛ - ТБЭ ": 106,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 155,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 76,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 99,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 27,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 12,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 5,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 11,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 52,
  "Энэбишийн БОЛОРЧУЛУУН ": 150,
  "Батбаатарын ГАНПҮРЭВ ": 73,
  "Энхбаярын МӨНХСАЙХАН ": 206
 },
 {
  "id": 10,
  "Жамъянгийн МӨНХБАТ - МАН ": 271,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 245,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 175,
  "Нямжавын БАТБАЯР - АН ": 54,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 45,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 106,
  "Нацагийн УДВАЛ - ТБЭ ": 21,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 30,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 10,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 64,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 27,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 4,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 8,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 13,
  "Энэбишийн БОЛОРЧУЛУУН ": 43,
  "Батбаатарын ГАНПҮРЭВ ": 14,
  "Энхбаярын МӨНХСАЙХАН ": 85
 },
 {
  "id": 11,
  "Жамъянгийн МӨНХБАТ - МАН ": 438,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 545,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 428,
  "Нямжавын БАТБАЯР - АН ": 194,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 173,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 292,
  "Нацагийн УДВАЛ - ТБЭ ": 61,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 111,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 58,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 82,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 19,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 20,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 4,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 6,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 58,
  "Энэбишийн БОЛОРЧУЛУУН ": 123,
  "Батбаатарын ГАНПҮРЭВ ": 44,
  "Энхбаярын МӨНХСАЙХАН ": 147
 },
 {
  "id": 12,
  "Жамъянгийн МӨНХБАТ - МАН ": 211,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 173,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 162,
  "Нямжавын БАТБАЯР - АН ": 68,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 62,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 95,
  "Нацагийн УДВАЛ - ТБЭ ": 9,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 10,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 6,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 5,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 1,
  "Энэбишийн БОЛОРЧУЛУУН ": 19,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 17
 },
 {
  "id": 13,
  "Жамъянгийн МӨНХБАТ - МАН ": 132,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 101,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 80,
  "Нямжавын БАТБАЯР - АН ": 93,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 81,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 132,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 17,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 8,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 4,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 4,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 2,
  "Энэбишийн БОЛОРЧУЛУУН ": 43,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 33
 },
 {
  "id": 14,
  "Жамъянгийн МӨНХБАТ - МАН ": 187,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 149,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 111,
  "Нямжавын БАТБАЯР - АН ": 42,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 41,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 102,
  "Нацагийн УДВАЛ - ТБЭ ": 8,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 10,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 11,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 23,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 3,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 0,
  "Энэбишийн БОЛОРЧУЛУУН ": 40,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 29
 },
 {
  "id": 15,
  "Жамъянгийн МӨНХБАТ - МАН ": 219,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 168,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 141,
  "Нямжавын БАТБАЯР - АН ": 70,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 62,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 116,
  "Нацагийн УДВАЛ - ТБЭ ": 15,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 15,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 9,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 13,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 9,
  "Энэбишийн БОЛОРЧУЛУУН ": 13,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 28
 },
 {
  "id": 16,
  "Жамъянгийн МӨНХБАТ - МАН ": 249,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 178,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 162,
  "Нямжавын БАТБАЯР - АН ": 86,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 90,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 178,
  "Нацагийн УДВАЛ - ТБЭ ": 10,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 27,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 11,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 4,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 22,
  "Энэбишийн БОЛОРЧУЛУУН ": 38,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 61
 },
 {
  "id": 17,
  "Жамъянгийн МӨНХБАТ - МАН ": 279,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 227,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 174,
  "Нямжавын БАТБАЯР - АН ": 101,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 89,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 150,
  "Нацагийн УДВАЛ - ТБЭ ": 25,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 25,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 24,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 9,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 12,
  "Энэбишийн БОЛОРЧУЛУУН ": 93,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 48
 },
 {
  "id": 18,
  "Жамъянгийн МӨНХБАТ - МАН ": 184,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 201,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 216,
  "Нямжавын БАТБАЯР - АН ": 20,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 34,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 129,
  "Нацагийн УДВАЛ - ТБЭ ": 4,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 14,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 19,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 25,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 0,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 9,
  "Энэбишийн БОЛОРЧУЛУУН ": 86,
  "Батбаатарын ГАНПҮРЭВ ": 11,
  "Энхбаярын МӨНХСАЙХАН ": 149
 },
 {
  "id": 19,
  "Жамъянгийн МӨНХБАТ - МАН ": 241,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 267,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 296,
  "Нямжавын БАТБАЯР - АН ": 52,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 49,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 188,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 22,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 43,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 27,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 11,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 12,
  "Энэбишийн БОЛОРЧУЛУУН ": 110,
  "Батбаатарын ГАНПҮРЭВ ": 16,
  "Энхбаярын МӨНХСАЙХАН ": 237
 },
 {
  "id": 20,
  "Жамъянгийн МӨНХБАТ - МАН ": 110,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 138,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 127,
  "Нямжавын БАТБАЯР - АН ": 19,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 23,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 101,
  "Нацагийн УДВАЛ - ТБЭ ": 11,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 22,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 76,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 24,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 11,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 5,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 13,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 7,
  "Ранжилын БАДМААНЯМБУУ ": 12,
  "Энэбишийн БОЛОРЧУЛУУН ": 41,
  "Батбаатарын ГАНПҮРЭВ ": 7,
  "Энхбаярын МӨНХСАЙХАН ": 166
 },
 {
  "id": 21,
  "Жамъянгийн МӨНХБАТ - МАН ": 154,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 164,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 156,
  "Нямжавын БАТБАЯР - АН ": 25,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 23,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 41,
  "Нацагийн УДВАЛ - ТБЭ ": 13,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 8,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 9,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 8,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 5,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 8,
  "Энэбишийн БОЛОРЧУЛУУН ": 18,
  "Батбаатарын ГАНПҮРЭВ ": 8,
  "Энхбаярын МӨНХСАЙХАН ": 45
 },
 {
  "id": 22,
  "Жамъянгийн МӨНХБАТ - МАН ": 205,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 205,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 215,
  "Нямжавын БАТБАЯР - АН ": 72,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 110,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 222,
  "Нацагийн УДВАЛ - ТБЭ ": 47,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 84,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 33,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 66,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 15,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 5,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 6,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 15,
  "Энэбишийн БОЛОРЧУЛУУН ": 50,
  "Батбаатарын ГАНПҮРЭВ ": 6,
  "Энхбаярын МӨНХСАЙХАН ": 143
 },
 {
  "id": 23,
  "Жамъянгийн МӨНХБАТ - МАН ": 132,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 132,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 161,
  "Нямжавын БАТБАЯР - АН ": 184,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 147,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 199,
  "Нацагийн УДВАЛ - ТБЭ ": 9,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 15,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 7,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 14,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 1,
  "Энэбишийн БОЛОРЧУЛУУН ": 32,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 62
 },
 {
  "id": 24,
  "Жамъянгийн МӨНХБАТ - МАН ": 80,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 86,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 103,
  "Нямжавын БАТБАЯР - АН ": 84,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 99,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 152,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 32,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 14,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 22,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 10,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 13,
  "Энэбишийн БОЛОРЧУЛУУН ": 54,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 39
 },
 {
  "id": 25,
  "Жамъянгийн МӨНХБАТ - МАН ": 193,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 178,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 193,
  "Нямжавын БАТБАЯР - АН ": 49,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 71,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 143,
  "Нацагийн УДВАЛ - ТБЭ ": 27,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 38,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 18,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 37,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 14,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 5,
  "Энэбишийн БОЛОРЧУЛУУН ": 30,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 49
 },
 {
  "id": 26,
  "Жамъянгийн МӨНХБАТ - МАН ": 232,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 253,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 238,
  "Нямжавын БАТБАЯР - АН ": 111,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 118,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 227,
  "Нацагийн УДВАЛ - ТБЭ ": 41,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 71,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 35,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 50,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 16,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 11,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 8,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 12,
  "Энэбишийн БОЛОРЧУЛУУН ": 83,
  "Батбаатарын ГАНПҮРЭВ ": 6,
  "Энхбаярын МӨНХСАЙХАН ": 174
 },
 {
  "id": 27,
  "Жамъянгийн МӨНХБАТ - МАН ": 111,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 151,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 220,
  "Нямжавын БАТБАЯР - АН ": 41,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 80,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 109,
  "Нацагийн УДВАЛ - ТБЭ ": 24,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 9,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 17,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 16,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 3,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 3,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 92,
  "Энэбишийн БОЛОРЧУЛУУН ": 37,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 42
 },
 {
  "id": 28,
  "Жамъянгийн МӨНХБАТ - МАН ": 127,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 151,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 241,
  "Нямжавын БАТБАЯР - АН ": 77,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 83,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 148,
  "Нацагийн УДВАЛ - ТБЭ ": 25,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 41,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 21,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 67,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 16,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 75,
  "Энэбишийн БОЛОРЧУЛУУН ": 30,
  "Батбаатарын ГАНПҮРЭВ ": 13,
  "Энхбаярын МӨНХСАЙХАН ": 49
 },
 {
  "id": 29,
  "Жамъянгийн МӨНХБАТ - МАН ": 167,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 221,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 272,
  "Нямжавын БАТБАЯР - АН ": 62,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 67,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 122,
  "Нацагийн УДВАЛ - ТБЭ ": 18,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 24,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 13,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 33,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 4,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 43,
  "Энэбишийн БОЛОРЧУЛУУН ": 27,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 89
 },
 {
  "id": 30,
  "Жамъянгийн МӨНХБАТ - МАН ": 231,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 251,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 329,
  "Нямжавын БАТБАЯР - АН ": 78,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 72,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 176,
  "Нацагийн УДВАЛ - ТБЭ ": 17,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 37,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 10,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 52,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 13,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 4,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 61,
  "Энэбишийн БОЛОРЧУЛУУН ": 31,
  "Батбаатарын ГАНПҮРЭВ ": 15,
  "Энхбаярын МӨНХСАЙХАН ": 51
 },
 {
  "id": 31,
  "Жамъянгийн МӨНХБАТ - МАН ": 224,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 247,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 378,
  "Нямжавын БАТБАЯР - АН ": 71,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 79,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 138,
  "Нацагийн УДВАЛ - ТБЭ ": 39,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 43,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 27,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 31,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 3,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 72,
  "Энэбишийн БОЛОРЧУЛУУН ": 57,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 83
 },
 {
  "id": 32,
  "Жамъянгийн МӨНХБАТ - МАН ": 224,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 259,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 366,
  "Нямжавын БАТБАЯР - АН ": 102,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 99,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 177,
  "Нацагийн УДВАЛ - ТБЭ ": 40,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 55,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 37,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 38,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 13,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 4,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 41,
  "Энэбишийн БОЛОРЧУЛУУН ": 60,
  "Батбаатарын ГАНПҮРЭВ ": 9,
  "Энхбаярын МӨНХСАЙХАН ": 76
 },
 {
  "id": 33,
  "Жамъянгийн МӨНХБАТ - МАН ": 157,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 96,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 136,
  "Нямжавын БАТБАЯР - АН ": 48,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 107,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 163,
  "Нацагийн УДВАЛ - ТБЭ ": 21,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 34,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 5,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 11,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 90,
  "Энэбишийн БОЛОРЧУЛУУН ": 82,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 50
 },
 {
  "id": 34,
  "Жамъянгийн МӨНХБАТ - МАН ": 165,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 120,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 125,
  "Нямжавын БАТБАЯР - АН ": 82,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 138,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 214,
  "Нацагийн УДВАЛ - ТБЭ ": 45,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 20,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 12,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 18,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 85,
  "Энэбишийн БОЛОРЧУЛУУН ": 89,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 61
 },
 {
  "id": 35,
  "Жамъянгийн МӨНХБАТ - МАН ": 174,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 120,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 156,
  "Нямжавын БАТБАЯР - АН ": 29,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 46,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 135,
  "Нацагийн УДВАЛ - ТБЭ ": 14,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 17,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 3,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 9,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 61,
  "Энэбишийн БОЛОРЧУЛУУН ": 38,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 18
 },
 {
  "id": 36,
  "Жамъянгийн МӨНХБАТ - МАН ": 126,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 100,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 120,
  "Нямжавын БАТБАЯР - АН ": 45,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 89,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 159,
  "Нацагийн УДВАЛ - ТБЭ ": 17,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 28,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 7,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 18,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 4,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 68,
  "Энэбишийн БОЛОРЧУЛУУН ": 59,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 56
 },
 {
  "id": 37,
  "Жамъянгийн МӨНХБАТ - МАН ": 153,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 107,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 123,
  "Нямжавын БАТБАЯР - АН ": 66,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 111,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 188,
  "Нацагийн УДВАЛ - ТБЭ ": 5,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 53,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 5,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 12,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 83,
  "Энэбишийн БОЛОРЧУЛУУН ": 49,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 52
 },
 {
  "id": 38,
  "Жамъянгийн МӨНХБАТ - МАН ": 244,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 175,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 185,
  "Нямжавын БАТБАЯР - АН ": 156,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 187,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 254,
  "Нацагийн УДВАЛ - ТБЭ ": 21,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 18,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 14,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 22,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 5,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 13,
  "Энэбишийн БОЛОРЧУЛУУН ": 52,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 34
 },
 {
  "id": 39,
  "Жамъянгийн МӨНХБАТ - МАН ": 304,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 252,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 225,
  "Нямжавын БАТБАЯР - АН ": 99,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 127,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 151,
  "Нацагийн УДВАЛ - ТБЭ ": 29,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 19,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 19,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 21,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 12,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 26,
  "Энэбишийн БОЛОРЧУЛУУН ": 40,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 26
 },
 {
  "id": 40,
  "Жамъянгийн МӨНХБАТ - МАН ": 188,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 155,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 138,
  "Нямжавын БАТБАЯР - АН ": 129,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 164,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 178,
  "Нацагийн УДВАЛ - ТБЭ ": 22,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 29,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 32,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 13,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 30,
  "Энэбишийн БОЛОРЧУЛУУН ": 37,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 35
 },
 {
  "id": 41,
  "Жамъянгийн МӨНХБАТ - МАН ": 220,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 212,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 189,
  "Нямжавын БАТБАЯР - АН ": 108,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 159,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 177,
  "Нацагийн УДВАЛ - ТБЭ ": 20,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 12,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 11,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 19,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 5,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 3,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 22,
  "Энэбишийн БОЛОРЧУЛУУН ": 43,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 18
 },
 {
  "id": 42,
  "Жамъянгийн МӨНХБАТ - МАН ": 393,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 325,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 342,
  "Нямжавын БАТБАЯР - АН ": 148,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 162,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 296,
  "Нацагийн УДВАЛ - ТБЭ ": 21,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 41,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 14,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 135,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 17,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 3,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 14,
  "Энэбишийн БОЛОРЧУЛУУН ": 53,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 143
 },
 {
  "id": 43,
  "Жамъянгийн МӨНХБАТ - МАН ": 328,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 272,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 340,
  "Нямжавын БАТБАЯР - АН ": 142,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 114,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 277,
  "Нацагийн УДВАЛ - ТБЭ ": 42,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 79,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 24,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 56,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 10,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 48,
  "Энэбишийн БОЛОРЧУЛУУН ": 62,
  "Батбаатарын ГАНПҮРЭВ ": 14,
  "Энхбаярын МӨНХСАЙХАН ": 73
 },
 {
  "id": 44,
  "Жамъянгийн МӨНХБАТ - МАН ": 205,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 252,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 216,
  "Нямжавын БАТБАЯР - АН ": 60,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 53,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 168,
  "Нацагийн УДВАЛ - ТБЭ ": 17,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 31,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 16,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 59,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 13,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 12,
  "Энэбишийн БОЛОРЧУЛУУН ": 58,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 84
 },
 {
  "id": 45,
  "Жамъянгийн МӨНХБАТ - МАН ": 280,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 302,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 336,
  "Нямжавын БАТБАЯР - АН ": 70,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 111,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 218,
  "Нацагийн УДВАЛ - ТБЭ ": 38,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 45,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 20,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 212,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 28,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 7,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 41,
  "Энэбишийн БОЛОРЧУЛУУН ": 51,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 70
 },
 {
  "id": 46,
  "Жамъянгийн МӨНХБАТ - МАН ": 242,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 259,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 284,
  "Нямжавын БАТБАЯР - АН ": 134,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 159,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 253,
  "Нацагийн УДВАЛ - ТБЭ ": 22,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 18,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 18,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 144,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 16,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 8,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 15,
  "Энэбишийн БОЛОРЧУЛУУН ": 51,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 63
 },
 {
  "id": 47,
  "Жамъянгийн МӨНХБАТ - МАН ": 192,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 193,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 197,
  "Нямжавын БАТБАЯР - АН ": 75,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 76,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 147,
  "Нацагийн УДВАЛ - ТБЭ ": 24,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 39,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 19,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 30,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 12,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 10,
  "Энэбишийн БОЛОРЧУЛУУН ": 39,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 124
 },
 {
  "id": 48,
  "Жамъянгийн МӨНХБАТ - МАН ": 174,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 147,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 157,
  "Нямжавын БАТБАЯР - АН ": 154,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 113,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 193,
  "Нацагийн УДВАЛ - ТБЭ ": 28,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 30,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 26,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 13,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 3,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 1,
  "Энэбишийн БОЛОРЧУЛУУН ": 22,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 125
 },
 {
  "id": 49,
  "Жамъянгийн МӨНХБАТ - МАН ": 227,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 229,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 264,
  "Нямжавын БАТБАЯР - АН ": 99,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 91,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 166,
  "Нацагийн УДВАЛ - ТБЭ ": 22,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 16,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 15,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 35,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 9,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 5,
  "Энэбишийн БОЛОРЧУЛУУН ": 34,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 127
 },
 {
  "id": 50,
  "Жамъянгийн МӨНХБАТ - МАН ": 136,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 145,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 162,
  "Нямжавын БАТБАЯР - АН ": 58,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 45,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 94,
  "Нацагийн УДВАЛ - ТБЭ ": 29,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 21,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 18,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 27,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 5,
  "Энэбишийн БОЛОРЧУЛУУН ": 12,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 70
 },
 {
  "id": 51,
  "Жамъянгийн МӨНХБАТ - МАН ": 115,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 108,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 125,
  "Нямжавын БАТБАЯР - АН ": 112,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 95,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 145,
  "Нацагийн УДВАЛ - ТБЭ ": 13,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 13,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 18,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 30,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 4,
  "Энэбишийн БОЛОРЧУЛУУН ": 16,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 39
 },
 {
  "id": 52,
  "Жамъянгийн МӨНХБАТ - МАН ": 196,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 198,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 244,
  "Нямжавын БАТБАЯР - АН ": 94,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 46,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 174,
  "Нацагийн УДВАЛ - ТБЭ ": 28,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 28,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 21,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 59,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 19,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 5,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 14,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 14,
  "Ранжилын БАДМААНЯМБУУ ": 1,
  "Энэбишийн БОЛОРЧУЛУУН ": 22,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 161
 },
 {
  "id": 53,
  "Жамъянгийн МӨНХБАТ - МАН ": 103,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 83,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 122,
  "Нямжавын БАТБАЯР - АН ": 78,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 44,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 137,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 14,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 11,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 60,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 9,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 4,
  "Энэбишийн БОЛОРЧУЛУУН ": 17,
  "Батбаатарын ГАНПҮРЭВ ": 9,
  "Энхбаярын МӨНХСАЙХАН ": 104
 },
 {
  "id": 54,
  "Жамъянгийн МӨНХБАТ - МАН ": 176,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 213,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 166,
  "Нямжавын БАТБАЯР - АН ": 94,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 94,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 133,
  "Нацагийн УДВАЛ - ТБЭ ": 18,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 41,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 20,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 33,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 2,
  "Энэбишийн БОЛОРЧУЛУУН ": 35,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 27
 },
 {
  "id": 55,
  "Жамъянгийн МӨНХБАТ - МАН ": 188,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 211,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 162,
  "Нямжавын БАТБАЯР - АН ": 83,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 74,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 153,
  "Нацагийн УДВАЛ - ТБЭ ": 17,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 38,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 25,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 35,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 18,
  "Энэбишийн БОЛОРЧУЛУУН ": 26,
  "Батбаатарын ГАНПҮРЭВ ": 6,
  "Энхбаярын МӨНХСАЙХАН ": 15
 },
 {
  "id": 56,
  "Жамъянгийн МӨНХБАТ - МАН ": 204,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 233,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 191,
  "Нямжавын БАТБАЯР - АН ": 116,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 109,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 180,
  "Нацагийн УДВАЛ - ТБЭ ": 15,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 22,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 18,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 35,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 4,
  "Энэбишийн БОЛОРЧУЛУУН ": 42,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 12
 },
 {
  "id": 57,
  "Жамъянгийн МӨНХБАТ - МАН ": 179,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 196,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 166,
  "Нямжавын БАТБАЯР - АН ": 114,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 112,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 141,
  "Нацагийн УДВАЛ - ТБЭ ": 19,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 29,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 9,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 10,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 15,
  "Энэбишийн БОЛОРЧУЛУУН ": 30,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 12
 },
 {
  "id": 58,
  "Жамъянгийн МӨНХБАТ - МАН ": 196,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 175,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 180,
  "Нямжавын БАТБАЯР - АН ": 146,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 158,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 172,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 11,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 13,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 21,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 8,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 3,
  "Энэбишийн БОЛОРЧУЛУУН ": 29,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 21
 },
 {
  "id": 59,
  "Жамъянгийн МӨНХБАТ - МАН ": 154,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 136,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 143,
  "Нямжавын БАТБАЯР - АН ": 185,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 213,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 218,
  "Нацагийн УДВАЛ - ТБЭ ": 16,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 16,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 24,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 21,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 9,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 12,
  "Энэбишийн БОЛОРЧУЛУУН ": 39,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 16
 },
 {
  "id": 60,
  "Жамъянгийн МӨНХБАТ - МАН ": 190,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 164,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 166,
  "Нямжавын БАТБАЯР - АН ": 152,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 162,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 169,
  "Нацагийн УДВАЛ - ТБЭ ": 17,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 39,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 30,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 30,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 8,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 6,
  "Энэбишийн БОЛОРЧУЛУУН ": 42,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 15
 },
 {
  "id": 61,
  "Жамъянгийн МӨНХБАТ - МАН ": 206,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 176,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 165,
  "Нямжавын БАТБАЯР - АН ": 110,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 113,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 133,
  "Нацагийн УДВАЛ - ТБЭ ": 15,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 29,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 19,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 11,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 2,
  "Энэбишийн БОЛОРЧУЛУУН ": 35,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 21
 },
 {
  "id": 62,
  "Жамъянгийн МӨНХБАТ - МАН ": 172,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 157,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 148,
  "Нямжавын БАТБАЯР - АН ": 106,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 114,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 130,
  "Нацагийн УДВАЛ - ТБЭ ": 20,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 30,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 28,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 30,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 11,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 10,
  "Энэбишийн БОЛОРЧУЛУУН ": 30,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 29
 },
 {
  "id": 63,
  "Жамъянгийн МӨНХБАТ - МАН ": 83,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 78,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 108,
  "Нямжавын БАТБАЯР - АН ": 86,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 68,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 140,
  "Нацагийн УДВАЛ - ТБЭ ": 1,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 7,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 7,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 36,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 9,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 9,
  "Энэбишийн БОЛОРЧУЛУУН ": 72,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 55
 },
 {
  "id": 64,
  "Жамъянгийн МӨНХБАТ - МАН ": 72,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 80,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 109,
  "Нямжавын БАТБАЯР - АН ": 18,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 30,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 70,
  "Нацагийн УДВАЛ - ТБЭ ": 3,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 6,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 7,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 8,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 4,
  "Энэбишийн БОЛОРЧУЛУУН ": 29,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 48
 },
 {
  "id": 65,
  "Жамъянгийн МӨНХБАТ - МАН ": 111,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 106,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 146,
  "Нямжавын БАТБАЯР - АН ": 64,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 84,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 108,
  "Нацагийн УДВАЛ - ТБЭ ": 16,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 14,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 12,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 23,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 6,
  "Энэбишийн БОЛОРЧУЛУУН ": 34,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 29
 },
 {
  "id": 66,
  "Жамъянгийн МӨНХБАТ - МАН ": 136,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 141,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 196,
  "Нямжавын БАТБАЯР - АН ": 32,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 46,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 94,
  "Нацагийн УДВАЛ - ТБЭ ": 7,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 6,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 6,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 17,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 2,
  "Энэбишийн БОЛОРЧУЛУУН ": 16,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 20
 },
 {
  "id": 67,
  "Жамъянгийн МӨНХБАТ - МАН ": 146,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 145,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 181,
  "Нямжавын БАТБАЯР - АН ": 95,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 110,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 143,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 12,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 9,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 39,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 18,
  "Энэбишийн БОЛОРЧУЛУУН ": 49,
  "Батбаатарын ГАНПҮРЭВ ": 7,
  "Энхбаярын МӨНХСАЙХАН ": 19
 },
 {
  "id": 68,
  "Жамъянгийн МӨНХБАТ - МАН ": 167,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 182,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 243,
  "Нямжавын БАТБАЯР - АН ": 63,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 66,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 120,
  "Нацагийн УДВАЛ - ТБЭ ": 22,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 23,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 30,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 60,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 22,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 15,
  "Энэбишийн БОЛОРЧУЛУУН ": 49,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 44
 },
 {
  "id": 69,
  "Жамъянгийн МӨНХБАТ - МАН ": 217,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 171,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 144,
  "Нямжавын БАТБАЯР - АН ": 66,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 91,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 164,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 17,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 10,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 26,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 5,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 4,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 21,
  "Энэбишийн БОЛОРЧУЛУУН ": 38,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 79
 },
 {
  "id": 70,
  "Жамъянгийн МӨНХБАТ - МАН ": 180,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 143,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 128,
  "Нямжавын БАТБАЯР - АН ": 94,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 106,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 124,
  "Нацагийн УДВАЛ - ТБЭ ": 9,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 7,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 5,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 12,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 15,
  "Энэбишийн БОЛОРЧУЛУУН ": 23,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 55
 },
 {
  "id": 71,
  "Жамъянгийн МӨНХБАТ - МАН ": 178,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 107,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 109,
  "Нямжавын БАТБАЯР - АН ": 37,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 54,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 134,
  "Нацагийн УДВАЛ - ТБЭ ": 32,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 30,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 28,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 36,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 4,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 93,
  "Энэбишийн БОЛОРЧУЛУУН ": 38,
  "Батбаатарын ГАНПҮРЭВ ": 8,
  "Энхбаярын МӨНХСАЙХАН ": 131
 },
 {
  "id": 72,
  "Жамъянгийн МӨНХБАТ - МАН ": 127,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 91,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 90,
  "Нямжавын БАТБАЯР - АН ": 27,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 42,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 76,
  "Нацагийн УДВАЛ - ТБЭ ": 14,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 20,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 4,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 8,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 26,
  "Энэбишийн БОЛОРЧУЛУУН ": 61,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 79
 },
 {
  "id": 73,
  "Жамъянгийн МӨНХБАТ - МАН ": 178,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 154,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 136,
  "Нямжавын БАТБАЯР - АН ": 94,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 71,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 141,
  "Нацагийн УДВАЛ - ТБЭ ": 26,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 21,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 20,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 15,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 35,
  "Энэбишийн БОЛОРЧУЛУУН ": 40,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 88
 },
 {
  "id": 74,
  "Жамъянгийн МӨНХБАТ - МАН ": 320,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 233,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 229,
  "Нямжавын БАТБАЯР - АН ": 97,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 193,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 176,
  "Нацагийн УДВАЛ - ТБЭ ": 15,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 54,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 15,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 118,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 21,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 7,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 6,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 58,
  "Энэбишийн БОЛОРЧУЛУУН ": 53,
  "Батбаатарын ГАНПҮРЭВ ": 6,
  "Энхбаярын МӨНХСАЙХАН ": 71
 },
 {
  "id": 75,
  "Жамъянгийн МӨНХБАТ - МАН ": 160,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 126,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 92,
  "Нямжавын БАТБАЯР - АН ": 42,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 74,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 104,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 27,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 7,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 35,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 0,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 34,
  "Энэбишийн БОЛОРЧУЛУУН ": 54,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 43
 },
 {
  "id": 76,
  "Жамъянгийн МӨНХБАТ - МАН ": 160,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 108,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 101,
  "Нямжавын БАТБАЯР - АН ": 53,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 116,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 94,
  "Нацагийн УДВАЛ - ТБЭ ": 9,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 34,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 6,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 65,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 5,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 45,
  "Энэбишийн БОЛОРЧУЛУУН ": 36,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 11
 },
 {
  "id": 77,
  "Жамъянгийн МӨНХБАТ - МАН ": 208,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 153,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 129,
  "Нямжавын БАТБАЯР - АН ": 58,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 110,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 114,
  "Нацагийн УДВАЛ - ТБЭ ": 16,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 87,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 10,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 70,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 31,
  "Энэбишийн БОЛОРЧУЛУУН ": 46,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 49
 },
 {
  "id": 78,
  "Жамъянгийн МӨНХБАТ - МАН ": 155,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 100,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 93,
  "Нямжавын БАТБАЯР - АН ": 29,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 84,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 61,
  "Нацагийн УДВАЛ - ТБЭ ": 6,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 17,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 6,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 46,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 32,
  "Энэбишийн БОЛОРЧУЛУУН ": 46,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 28
 },
 {
  "id": 79,
  "Жамъянгийн МӨНХБАТ - МАН ": 252,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 222,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 174,
  "Нямжавын БАТБАЯР - АН ": 87,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 163,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 155,
  "Нацагийн УДВАЛ - ТБЭ ": 20,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 24,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 12,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 55,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 19,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 58,
  "Энэбишийн БОЛОРЧУЛУУН ": 49,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 55
 },
 {
  "id": 80,
  "Жамъянгийн МӨНХБАТ - МАН ": 167,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 167,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 230,
  "Нямжавын БАТБАЯР - АН ": 155,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 135,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 180,
  "Нацагийн УДВАЛ - ТБЭ ": 43,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 54,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 29,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 23,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 0,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 4,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 8,
  "Энэбишийн БОЛОРЧУЛУУН ": 35,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 84
 },
 {
  "id": 81,
  "Жамъянгийн МӨНХБАТ - МАН ": 129,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 132,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 162,
  "Нямжавын БАТБАЯР - АН ": 79,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 79,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 122,
  "Нацагийн УДВАЛ - ТБЭ ": 15,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 21,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 10,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 10,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 2,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 0,
  "Энэбишийн БОЛОРЧУЛУУН ": 28,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 42
 },
 {
  "id": 82,
  "Жамъянгийн МӨНХБАТ - МАН ": 148,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 154,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 169,
  "Нямжавын БАТБАЯР - АН ": 120,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 98,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 123,
  "Нацагийн УДВАЛ - ТБЭ ": 26,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 25,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 8,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 11,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 0,
  "Энэбишийн БОЛОРЧУЛУУН ": 26,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 51
 },
 {
  "id": 83,
  "Жамъянгийн МӨНХБАТ - МАН ": 79,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 75,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 97,
  "Нямжавын БАТБАЯР - АН ": 69,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 53,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 84,
  "Нацагийн УДВАЛ - ТБЭ ": 12,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 15,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 6,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 8,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 0,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 4,
  "Энэбишийн БОЛОРЧУЛУУН ": 17,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 41
 },
 {
  "id": 84,
  "Жамъянгийн МӨНХБАТ - МАН ": 173,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 190,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 155,
  "Нямжавын БАТБАЯР - АН ": 107,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 116,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 180,
  "Нацагийн УДВАЛ - ТБЭ ": 46,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 79,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 50,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 34,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 9,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 15,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 4,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 20,
  "Энэбишийн БОЛОРЧУЛУУН ": 180,
  "Батбаатарын ГАНПҮРЭВ ": 10,
  "Энхбаярын МӨНХСАЙХАН ": 50
 },
 {
  "id": 85,
  "Жамъянгийн МӨНХБАТ - МАН ": 221,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 219,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 163,
  "Нямжавын БАТБАЯР - АН ": 69,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 96,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 168,
  "Нацагийн УДВАЛ - ТБЭ ": 28,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 77,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 47,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 64,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 27,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 36,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 11,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 5,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 5,
  "Ранжилын БАДМААНЯМБУУ ": 75,
  "Энэбишийн БОЛОРЧУЛУУН ": 312,
  "Батбаатарын ГАНПҮРЭВ ": 18,
  "Энхбаярын МӨНХСАЙХАН ": 96
 },
 {
  "id": 86,
  "Жамъянгийн МӨНХБАТ - МАН ": 92,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 127,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 75,
  "Нямжавын БАТБАЯР - АН ": 84,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 87,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 158,
  "Нацагийн УДВАЛ - ТБЭ ": 31,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 56,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 23,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 46,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 37,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 15,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 40,
  "Энэбишийн БОЛОРЧУЛУУН ": 229,
  "Батбаатарын ГАНПҮРЭВ ": 15,
  "Энхбаярын МӨНХСАЙХАН ": 49
 },
 {
  "id": 87,
  "Жамъянгийн МӨНХБАТ - МАН ": 188,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 209,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 137,
  "Нямжавын БАТБАЯР - АН ": 64,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 79,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 167,
  "Нацагийн УДВАЛ - ТБЭ ": 23,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 58,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 19,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 44,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 54,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 11,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 3,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 103,
  "Энэбишийн БОЛОРЧУЛУУН ": 198,
  "Батбаатарын ГАНПҮРЭВ ": 17,
  "Энхбаярын МӨНХСАЙХАН ": 63
 },
 {
  "id": 88,
  "Жамъянгийн МӨНХБАТ - МАН ": 234,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 210,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 150,
  "Нямжавын БАТБАЯР - АН ": 81,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 101,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 192,
  "Нацагийн УДВАЛ - ТБЭ ": 43,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 60,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 31,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 11,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 13,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 4,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 57,
  "Энэбишийн БОЛОРЧУЛУУН ": 134,
  "Батбаатарын ГАНПҮРЭВ ": 12,
  "Энхбаярын МӨНХСАЙХАН ": 42
 },
 {
  "id": 89,
  "Жамъянгийн МӨНХБАТ - МАН ": 201,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 215,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 181,
  "Нямжавын БАТБАЯР - АН ": 83,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 83,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 137,
  "Нацагийн УДВАЛ - ТБЭ ": 27,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 41,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 32,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 16,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 20,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 10,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 67,
  "Энэбишийн БОЛОРЧУЛУУН ": 200,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 49
 },
 {
  "id": 90,
  "Жамъянгийн МӨНХБАТ - МАН ": 207,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 143,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 137,
  "Нямжавын БАТБАЯР - АН ": 57,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 66,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 96,
  "Нацагийн УДВАЛ - ТБЭ ": 23,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 14,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 21,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 19,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 43,
  "Энэбишийн БОЛОРЧУЛУУН ": 32,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 54
 },
 {
  "id": 91,
  "Жамъянгийн МӨНХБАТ - МАН ": 243,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 201,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 163,
  "Нямжавын БАТБАЯР - АН ": 82,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 72,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 115,
  "Нацагийн УДВАЛ - ТБЭ ": 42,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 65,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 20,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 50,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 17,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 17,
  "Энэбишийн БОЛОРЧУЛУУН ": 63,
  "Батбаатарын ГАНПҮРЭВ ": 7,
  "Энхбаярын МӨНХСАЙХАН ": 54
 },
 {
  "id": 92,
  "Жамъянгийн МӨНХБАТ - МАН ": 142,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 123,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 117,
  "Нямжавын БАТБАЯР - АН ": 120,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 111,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 154,
  "Нацагийн УДВАЛ - ТБЭ ": 41,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 40,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 23,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 17,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 8,
  "Энэбишийн БОЛОРЧУЛУУН ": 21,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 61
 },
 {
  "id": 93,
  "Жамъянгийн МӨНХБАТ - МАН ": 194,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 159,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 172,
  "Нямжавын БАТБАЯР - АН ": 57,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 60,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 77,
  "Нацагийн УДВАЛ - ТБЭ ": 24,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 16,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 18,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 26,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 10,
  "Энэбишийн БОЛОРЧУЛУУН ": 42,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 61
 },
 {
  "id": 94,
  "Жамъянгийн МӨНХБАТ - МАН ": 220,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 203,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 187,
  "Нямжавын БАТБАЯР - АН ": 117,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 118,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 158,
  "Нацагийн УДВАЛ - ТБЭ ": 30,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 29,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 21,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 19,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 7,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 19,
  "Энэбишийн БОЛОРЧУЛУУН ": 48,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 37
 },
 {
  "id": 95,
  "Жамъянгийн МӨНХБАТ - МАН ": 112,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 155,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 150,
  "Нямжавын БАТБАЯР - АН ": 106,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 102,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 137,
  "Нацагийн УДВАЛ - ТБЭ ": 23,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 49,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 22,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 9,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 3,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 1,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 16,
  "Энэбишийн БОЛОРЧУЛУУН ": 17,
  "Батбаатарын ГАНПҮРЭВ ": 1,
  "Энхбаярын МӨНХСАЙХАН ": 40
 },
 {
  "id": 96,
  "Жамъянгийн МӨНХБАТ - МАН ": 165,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 280,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 190,
  "Нямжавын БАТБАЯР - АН ": 195,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 207,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 293,
  "Нацагийн УДВАЛ - ТБЭ ": 63,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 93,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 30,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 36,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 6,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 2,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 5,
  "Энэбишийн БОЛОРЧУЛУУН ": 30,
  "Батбаатарын ГАНПҮРЭВ ": 0,
  "Энхбаярын МӨНХСАЙХАН ": 59
 },
 {
  "id": 97,
  "Жамъянгийн МӨНХБАТ - МАН ": 190,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 353,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 231,
  "Нямжавын БАТБАЯР - АН ": 106,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 136,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 207,
  "Нацагийн УДВАЛ - ТБЭ ": 66,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 78,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 43,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 57,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 12,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 5,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 3,
  "Ранжилын БАДМААНЯМБУУ ": 14,
  "Энэбишийн БОЛОРЧУЛУУН ": 62,
  "Батбаатарын ГАНПҮРЭВ ": 13,
  "Энхбаярын МӨНХСАЙХАН ": 84
 },
 {
  "id": 98,
  "Жамъянгийн МӨНХБАТ - МАН ": 93,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 159,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 109,
  "Нямжавын БАТБАЯР - АН ": 92,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 85,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 122,
  "Нацагийн УДВАЛ - ТБЭ ": 50,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 55,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 27,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 9,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 4,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 9,
  "Энэбишийн БОЛОРЧУЛУУН ": 11,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 35
 },
 {
  "id": 99,
  "Жамъянгийн МӨНХБАТ - МАН ": 158,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 220,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 167,
  "Нямжавын БАТБАЯР - АН ": 102,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 110,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 139,
  "Нацагийн УДВАЛ - ТБЭ ": 45,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 51,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 29,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 6,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 5,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 2,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 2,
  "Энэбишийн БОЛОРЧУЛУУН ": 20,
  "Батбаатарын ГАНПҮРЭВ ": 6,
  "Энхбаярын МӨНХСАЙХАН ": 21
 },
 {
  "id": 100,
  "Жамъянгийн МӨНХБАТ - МАН ": 223,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 145,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 119,
  "Нямжавын БАТБАЯР - АН ": 63,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 76,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 110,
  "Нацагийн УДВАЛ - ТБЭ ": 79,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 52,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 37,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 23,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 4,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 1,
  "Ранжилын БАДМААНЯМБУУ ": 29,
  "Энэбишийн БОЛОРЧУЛУУН ": 66,
  "Батбаатарын ГАНПҮРЭВ ": 4,
  "Энхбаярын МӨНХСАЙХАН ": 74
 },
 {
  "id": 101,
  "Жамъянгийн МӨНХБАТ - МАН ": 148,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 120,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 119,
  "Нямжавын БАТБАЯР - АН ": 124,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 109,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 162,
  "Нацагийн УДВАЛ - ТБЭ ": 37,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 25,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 13,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 20,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 10,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 0,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 0,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 0,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 1,
  "Энэбишийн БОЛОРЧУЛУУН ": 46,
  "Батбаатарын ГАНПҮРЭВ ": 2,
  "Энхбаярын МӨНХСАЙХАН ": 39
 },
 {
  "id": 102,
  "Жамъянгийн МӨНХБАТ - МАН ": 233,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 181,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 149,
  "Нямжавын БАТБАЯР - АН ": 134,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 134,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 196,
  "Нацагийн УДВАЛ - ТБЭ ": 106,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 79,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 31,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 28,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 3,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 2,
  "Ранжилын БАДМААНЯМБУУ ": 9,
  "Энэбишийн БОЛОРЧУЛУУН ": 98,
  "Батбаатарын ГАНПҮРЭВ ": 5,
  "Энхбаярын МӨНХСАЙХАН ": 47
 },
 {
  "id": 103,
  "Жамъянгийн МӨНХБАТ - МАН ": 217,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 185,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 164,
  "Нямжавын БАТБАЯР - АН ": 118,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 141,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 186,
  "Нацагийн УДВАЛ - ТБЭ ": 70,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 53,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 36,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 47,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 15,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 1,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 3,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 20,
  "Энэбишийн БОЛОРЧУЛУУН ": 64,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 50
 },
 {
  "id": 104,
  "Жамъянгийн МӨНХБАТ - МАН ": 268,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 235,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 183,
  "Нямжавын БАТБАЯР - АН ": 150,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 148,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 214,
  "Нацагийн УДВАЛ - ТБЭ ": 89,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 36,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 23,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 37,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 16,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 3,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 1,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 4,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 4,
  "Ранжилын БАДМААНЯМБУУ ": 11,
  "Энэбишийн БОЛОРЧУЛУУН ": 57,
  "Батбаатарын ГАНПҮРЭВ ": 3,
  "Энхбаярын МӨНХСАЙХАН ": 66
 },
 {
  "id": 105,
  "Жамъянгийн МӨНХБАТ - МАН ": 198,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 190,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 150,
  "Нямжавын БАТБАЯР - АН ": 102,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 98,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 153,
  "Нацагийн УДВАЛ - ТБЭ ": 96,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 69,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 46,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 25,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 5,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 6,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 2,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 4,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 0,
  "Ранжилын БАДМААНЯМБУУ ": 9,
  "Энэбишийн БОЛОРЧУЛУУН ": 70,
  "Батбаатарын ГАНПҮРЭВ ": 7,
  "Энхбаярын МӨНХСАЙХАН ": 51
 },
 {
  "id": "total",
  "Жамъянгийн МӨНХБАТ - МАН ": 21478,
  "Ёндонпэрэнлэйн БААТАРБИЛЭГ - МАН ": 21849,
  "Ганзоригийн ТЭМҮҮЛЭН - МАН ": 20204,
  "Нямжавын БАТБАЯР - АН ": 10041,
  "Дондогдоржийн ЗОЛЖАРГАЛ - АН ": 10663,
  "Пүрэвбаатарын МӨНХТУЛГА - АН ": 17155,
  "Нацагийн УДВАЛ - ТБЭ ": 3378,
  "Батаагийн ГАНЦЭЦЭГ - ТБЭ ": 4552,
  "Дуламжавын МӨНХ-ЭРДЭНЭ - ТБЭ ": 2545,
  "Даваадоржийн АРИУНЖАРГАЛ - ШЭ ": 4115,
  "Лхагваагийн ДАНСРАНБАВУУ - ШЭ ": 1053,
  "Раднаабазарын ЖАРГАЛМАА - ЗХЭЭ ": 518,
  "Жамбын САНДУЙЖАВ - ЗХЭЭ ": 171,
  "Долгорын СОСОРБАРАМ - СҮХ19Э ": 258,
  "Хадбаатарын ДЭМИДБАЛДАННЯМ - ЗОН ": 242,
  "Ранжилын БАДМААНЯМБУУ ": 2885,
  "Энэбишийн БОЛОРЧУЛУУН ": 6438,
  "Батбаатарын ГАНПҮРЭВ ": 1092,
  "Энхбаярын МӨНХСАЙХАН ": 7536
 }
]