export const election2020CandidatesBayankhongor = [
 {
  "id": 1,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 872,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 580,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 655,
  "Дашдондогийн ГАНБАТ - АН ": 627,
  "Пүрэвийн НҮРЗЭД - АН ": 518,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 495,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 73,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 413,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 56,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 46,
  "Очирын БАТСҮХ - ШЭ ": 21,
  "Маамын ГАНЗОРИГ - ШЭ ": 5,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 34,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 18,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 18,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 16,
  "Баасанжавын АНХБАЯР ": 53,
  "Батчулууны ОТГОНБАЯР ": 28,
  "Баярсайханы УУГАНБАЯР ": 25,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 268
 },
 {
  "id": 2,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 855,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 572,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 623,
  "Дашдондогийн ГАНБАТ - АН ": 744,
  "Пүрэвийн НҮРЗЭД - АН ": 674,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 669,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 101,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 240,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 78,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 26,
  "Очирын БАТСҮХ - ШЭ ": 20,
  "Маамын ГАНЗОРИГ - ШЭ ": 13,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 15,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 10,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 6,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 6,
  "Баасанжавын АНХБАЯР ": 16,
  "Батчулууны ОТГОНБАЯР ": 16,
  "Баярсайханы УУГАНБАЯР ": 15,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 167
 },
 {
  "id": 3,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 101,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 68,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 60,
  "Дашдондогийн ГАНБАТ - АН ": 95,
  "Пүрэвийн НҮРЗЭД - АН ": 77,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 75,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 2,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 44,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 8,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 5,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 7,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 25
 },
 {
  "id": 4,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 692,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 448,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 504,
  "Дашдондогийн ГАНБАТ - АН ": 559,
  "Пүрэвийн НҮРЗЭД - АН ": 531,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 487,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 71,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 196,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 41,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 18,
  "Очирын БАТСҮХ - ШЭ ": 13,
  "Маамын ГАНЗОРИГ - ШЭ ": 9,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 5,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 12,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 4,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 9,
  "Баасанжавын АНХБАЯР ": 20,
  "Батчулууны ОТГОНБАЯР ": 9,
  "Баярсайханы УУГАНБАЯР ": 11,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 162
 },
 {
  "id": 5,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 937,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 579,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 643,
  "Дашдондогийн ГАНБАТ - АН ": 789,
  "Пүрэвийн НҮРЗЭД - АН ": 657,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 630,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 69,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 256,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 43,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 41,
  "Очирын БАТСҮХ - ШЭ ": 17,
  "Маамын ГАНЗОРИГ - ШЭ ": 13,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 19,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 18,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 12,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 11,
  "Баасанжавын АНХБАЯР ": 26,
  "Батчулууны ОТГОНБАЯР ": 25,
  "Баярсайханы УУГАНБАЯР ": 18,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 237
 },
 {
  "id": 6,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 998,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 635,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 734,
  "Дашдондогийн ГАНБАТ - АН ": 678,
  "Пүрэвийн НҮРЗЭД - АН ": 563,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 520,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 85,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 304,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 53,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 60,
  "Очирын БАТСҮХ - ШЭ ": 27,
  "Маамын ГАНЗОРИГ - ШЭ ": 23,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 18,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 13,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 8,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 13,
  "Баасанжавын АНХБАЯР ": 34,
  "Батчулууны ОТГОНБАЯР ": 21,
  "Баярсайханы УУГАНБАЯР ": 20,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 251
 },
 {
  "id": 7,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 1039,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 712,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 754,
  "Дашдондогийн ГАНБАТ - АН ": 661,
  "Пүрэвийн НҮРЗЭД - АН ": 590,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 584,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 84,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 333,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 58,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 41,
  "Очирын БАТСҮХ - ШЭ ": 24,
  "Маамын ГАНЗОРИГ - ШЭ ": 12,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 25,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 8,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 12,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 15,
  "Баасанжавын АНХБАЯР ": 35,
  "Батчулууны ОТГОНБАЯР ": 16,
  "Баярсайханы УУГАНБАЯР ": 18,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 232
 },
 {
  "id": 8,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 620,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 450,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 470,
  "Дашдондогийн ГАНБАТ - АН ": 642,
  "Пүрэвийн НҮРЗЭД - АН ": 537,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 519,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 39,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 172,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 32,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 18,
  "Очирын БАТСҮХ - ШЭ ": 10,
  "Маамын ГАНЗОРИГ - ШЭ ": 11,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 15,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 5,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 9,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 9,
  "Баасанжавын АНХБАЯР ": 21,
  "Батчулууны ОТГОНБАЯР ": 8,
  "Баярсайханы УУГАНБАЯР ": 7,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 129
 },
 {
  "id": 9,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 461,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 319,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 360,
  "Дашдондогийн ГАНБАТ - АН ": 274,
  "Пүрэвийн НҮРЗЭД - АН ": 192,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 212,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 14,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 29,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 16,
  "Очирын БАТСҮХ - ШЭ ": 11,
  "Маамын ГАНЗОРИГ - ШЭ ": 6,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 11,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 79
 },
 {
  "id": 10,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 988,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 656,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 701,
  "Дашдондогийн ГАНБАТ - АН ": 627,
  "Пүрэвийн НҮРЗЭД - АН ": 476,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 522,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 46,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 229,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 33,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 18,
  "Очирын БАТСҮХ - ШЭ ": 12,
  "Маамын ГАНЗОРИГ - ШЭ ": 8,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 6,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 15,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 7,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 12,
  "Баасанжавын АНХБАЯР ": 24,
  "Батчулууны ОТГОНБАЯР ": 14,
  "Баярсайханы УУГАНБАЯР ": 10,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 153
 },
 {
  "id": 11,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 931,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 628,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 730,
  "Дашдондогийн ГАНБАТ - АН ": 753,
  "Пүрэвийн НҮРЗЭД - АН ": 574,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 581,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 47,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 304,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 23,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 38,
  "Очирын БАТСҮХ - ШЭ ": 19,
  "Маамын ГАНЗОРИГ - ШЭ ": 9,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 16,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 11,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 10,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 12,
  "Баасанжавын АНХБАЯР ": 48,
  "Батчулууны ОТГОНБАЯР ": 21,
  "Баярсайханы УУГАНБАЯР ": 17,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 223
 },
 {
  "id": 12,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 124,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 87,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 76,
  "Дашдондогийн ГАНБАТ - АН ": 269,
  "Пүрэвийн НҮРЗЭД - АН ": 234,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 243,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 23,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 15
 },
 {
  "id": 13,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 164,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 119,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 130,
  "Дашдондогийн ГАНБАТ - АН ": 200,
  "Пүрэвийн НҮРЗЭД - АН ": 163,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 171,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 17,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 7,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 15
 },
 {
  "id": 14,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 78,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 48,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 55,
  "Дашдондогийн ГАНБАТ - АН ": 146,
  "Пүрэвийн НҮРЗЭД - АН ": 126,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 146,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 13,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 0,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 14
 },
 {
  "id": 15,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 59,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 33,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 33,
  "Дашдондогийн ГАНБАТ - АН ": 115,
  "Пүрэвийн НҮРЗЭД - АН ": 121,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 113,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 23,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 8,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 13
 },
 {
  "id": 16,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 214,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 171,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 146,
  "Дашдондогийн ГАНБАТ - АН ": 203,
  "Пүрэвийн НҮРЗЭД - АН ": 148,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 173,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 20,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 24
 },
 {
  "id": 17,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 85,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 50,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 53,
  "Дашдондогийн ГАНБАТ - АН ": 150,
  "Пүрэвийн НҮРЗЭД - АН ": 141,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 142,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 17,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 14
 },
 {
  "id": 18,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 100,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 79,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 79,
  "Дашдондогийн ГАНБАТ - АН ": 90,
  "Пүрэвийн НҮРЗЭД - АН ": 68,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 74,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 7,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 0,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 4
 },
 {
  "id": 19,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 156,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 133,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 157,
  "Дашдондогийн ГАНБАТ - АН ": 181,
  "Пүрэвийн НҮРЗЭД - АН ": 151,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 156,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 5,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 3,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 26
 },
 {
  "id": 20,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 100,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 72,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 101,
  "Дашдондогийн ГАНБАТ - АН ": 105,
  "Пүрэвийн НҮРЗЭД - АН ": 92,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 99,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 8,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 14,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 5,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 31
 },
 {
  "id": 21,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 105,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 100,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 106,
  "Дашдондогийн ГАНБАТ - АН ": 88,
  "Пүрэвийн НҮРЗЭД - АН ": 94,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 102,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 4,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 9,
  "Очирын БАТСҮХ - ШЭ ": 8,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 40
 },
 {
  "id": 22,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 72,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 64,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 84,
  "Дашдондогийн ГАНБАТ - АН ": 80,
  "Пүрэвийн НҮРЗЭД - АН ": 72,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 71,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 9,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 17
 },
 {
  "id": 23,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 188,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 146,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 161,
  "Дашдондогийн ГАНБАТ - АН ": 171,
  "Пүрэвийн НҮРЗЭД - АН ": 146,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 173,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 10,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 12,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 9,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 34
 },
 {
  "id": 24,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 149,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 123,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 114,
  "Дашдондогийн ГАНБАТ - АН ": 101,
  "Пүрэвийн НҮРЗЭД - АН ": 101,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 96,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 6,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 25
 },
 {
  "id": 25,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 192,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 131,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 149,
  "Дашдондогийн ГАНБАТ - АН ": 151,
  "Пүрэвийн НҮРЗЭД - АН ": 121,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 123,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 16,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 21,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 13,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 2,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 46
 },
 {
  "id": 26,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 204,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 146,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 159,
  "Дашдондогийн ГАНБАТ - АН ": 108,
  "Пүрэвийн НҮРЗЭД - АН ": 113,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 140,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 6,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 8,
  "Очирын БАТСҮХ - ШЭ ": 23,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 38
 },
 {
  "id": 27,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 156,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 108,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 118,
  "Дашдондогийн ГАНБАТ - АН ": 110,
  "Пүрэвийн НҮРЗЭД - АН ": 102,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 109,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 23,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 11,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 2,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 43
 },
 {
  "id": 28,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 140,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 101,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 102,
  "Дашдондогийн ГАНБАТ - АН ": 153,
  "Пүрэвийн НҮРЗЭД - АН ": 151,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 148,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 12,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 11,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 12
 },
 {
  "id": 29,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 132,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 90,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 92,
  "Дашдондогийн ГАНБАТ - АН ": 241,
  "Пүрэвийн НҮРЗЭД - АН ": 232,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 225,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 18,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 12,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 15
 },
 {
  "id": 30,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 177,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 112,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 121,
  "Дашдондогийн ГАНБАТ - АН ": 317,
  "Пүрэвийн НҮРЗЭД - АН ": 287,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 275,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 6,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 17,
  "Маамын ГАНЗОРИГ - ШЭ ": 5,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 18
 },
 {
  "id": 31,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 274,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 192,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 195,
  "Дашдондогийн ГАНБАТ - АН ": 227,
  "Пүрэвийн НҮРЗЭД - АН ": 241,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 221,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 11,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 23,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 15,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 6,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 4,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 31
 },
 {
  "id": 32,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 277,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 200,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 216,
  "Дашдондогийн ГАНБАТ - АН ": 266,
  "Пүрэвийн НҮРЗЭД - АН ": 243,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 234,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 14,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 27,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 9,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 30
 },
 {
  "id": 33,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 230,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 170,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 159,
  "Дашдондогийн ГАНБАТ - АН ": 213,
  "Пүрэвийн НҮРЗЭД - АН ": 192,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 160,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 25,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 149,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 19,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 11,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 24
 },
 {
  "id": 34,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 235,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 152,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 163,
  "Дашдондогийн ГАНБАТ - АН ": 176,
  "Пүрэвийн НҮРЗЭД - АН ": 136,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 97,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 102,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 9,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 3,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 32
 },
 {
  "id": 35,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 168,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 140,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 110,
  "Дашдондогийн ГАНБАТ - АН ": 128,
  "Пүрэвийн НҮРЗЭД - АН ": 124,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 101,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 0,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 49,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 25
 },
 {
  "id": 36,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 148,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 126,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 116,
  "Дашдондогийн ГАНБАТ - АН ": 147,
  "Пүрэвийн НҮРЗЭД - АН ": 158,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 123,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 98,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 4,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 5,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 3,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 27
 },
 {
  "id": 37,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 204,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 161,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 142,
  "Дашдондогийн ГАНБАТ - АН ": 243,
  "Пүрэвийн НҮРЗЭД - АН ": 232,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 216,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 30,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 49,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 19,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 39,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 3,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 2,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 15,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 25
 },
 {
  "id": 38,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 182,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 139,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 146,
  "Дашдондогийн ГАНБАТ - АН ": 266,
  "Пүрэвийн НҮРЗЭД - АН ": 250,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 251,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 13,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 11,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 13,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 3,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 16
 },
 {
  "id": 39,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 137,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 114,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 127,
  "Дашдондогийн ГАНБАТ - АН ": 123,
  "Пүрэвийн НҮРЗЭД - АН ": 120,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 123,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 9,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 19,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 3,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 5,
  "Батчулууны ОТГОНБАЯР ": 6,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 12
 },
 {
  "id": 40,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 82,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 57,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 66,
  "Дашдондогийн ГАНБАТ - АН ": 145,
  "Пүрэвийн НҮРЗЭД - АН ": 130,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 125,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 19,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 19,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 3,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 12
 },
 {
  "id": 41,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 126,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 89,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 110,
  "Дашдондогийн ГАНБАТ - АН ": 353,
  "Пүрэвийн НҮРЗЭД - АН ": 367,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 359,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 27,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 15,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 9,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 3,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 51,
  "Батчулууны ОТГОНБАЯР ": 6,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 24
 },
 {
  "id": 42,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 182,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 147,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 163,
  "Дашдондогийн ГАНБАТ - АН ": 256,
  "Пүрэвийн НҮРЗЭД - АН ": 241,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 247,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 7,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 11
 },
 {
  "id": 43,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 165,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 125,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 134,
  "Дашдондогийн ГАНБАТ - АН ": 198,
  "Пүрэвийн НҮРЗЭД - АН ": 180,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 191,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 17,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 14,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 16,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 5,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 5,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 26
 },
 {
  "id": 44,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 121,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 96,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 101,
  "Дашдондогийн ГАНБАТ - АН ": 152,
  "Пүрэвийн НҮРЗЭД - АН ": 148,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 143,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 6,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 7,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 23
 },
 {
  "id": 45,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 118,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 70,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 94,
  "Дашдондогийн ГАНБАТ - АН ": 176,
  "Пүрэвийн НҮРЗЭД - АН ": 165,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 162,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 10,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 9,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 15,
  "Батчулууны ОТГОНБАЯР ": 5,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 23
 },
 {
  "id": 46,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 156,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 98,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 96,
  "Дашдондогийн ГАНБАТ - АН ": 82,
  "Пүрэвийн НҮРЗЭД - АН ": 31,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 76,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 15,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 38
 },
 {
  "id": 47,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 161,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 100,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 92,
  "Дашдондогийн ГАНБАТ - АН ": 146,
  "Пүрэвийн НҮРЗЭД - АН ": 93,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 144,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 38,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 10,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 76
 },
 {
  "id": 48,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 213,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 171,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 149,
  "Дашдондогийн ГАНБАТ - АН ": 131,
  "Пүрэвийн НҮРЗЭД - АН ": 79,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 127,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 9,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 51,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 3,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 76
 },
 {
  "id": 49,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 107,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 78,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 84,
  "Дашдондогийн ГАНБАТ - АН ": 97,
  "Пүрэвийн НҮРЗЭД - АН ": 97,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 111,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 3,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 34
 },
 {
  "id": 50,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 178,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 120,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 120,
  "Дашдондогийн ГАНБАТ - АН ": 118,
  "Пүрэвийн НҮРЗЭД - АН ": 87,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 102,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 26,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 3,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 27
 },
 {
  "id": 51,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 157,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 77,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 102,
  "Дашдондогийн ГАНБАТ - АН ": 140,
  "Пүрэвийн НҮРЗЭД - АН ": 71,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 146,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 33,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 9,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 10,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 70
 },
 {
  "id": 52,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 328,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 283,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 275,
  "Дашдондогийн ГАНБАТ - АН ": 178,
  "Пүрэвийн НҮРЗЭД - АН ": 177,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 156,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 47,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 32,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 18,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 16,
  "Очирын БАТСҮХ - ШЭ ": 43,
  "Маамын ГАНЗОРИГ - ШЭ ": 9,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 5,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 4,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 87
 },
 {
  "id": 53,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 200,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 138,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 152,
  "Дашдондогийн ГАНБАТ - АН ": 274,
  "Пүрэвийн НҮРЗЭД - АН ": 210,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 206,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 33,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 12,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 11,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 102
 },
 {
  "id": 54,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 173,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 133,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 146,
  "Дашдондогийн ГАНБАТ - АН ": 136,
  "Пүрэвийн НҮРЗЭД - АН ": 124,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 124,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 20,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 12,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 39
 },
 {
  "id": 55,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 212,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 193,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 178,
  "Дашдондогийн ГАНБАТ - АН ": 173,
  "Пүрэвийн НҮРЗЭД - АН ": 139,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 143,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 40,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 17,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 10,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 8,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 3,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 70
 },
 {
  "id": 56,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 122,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 116,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 111,
  "Дашдондогийн ГАНБАТ - АН ": 117,
  "Пүрэвийн НҮРЗЭД - АН ": 126,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 123,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 1,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 11,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 35
 },
 {
  "id": 57,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 173,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 161,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 160,
  "Дашдондогийн ГАНБАТ - АН ": 208,
  "Пүрэвийн НҮРЗЭД - АН ": 199,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 195,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 15,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 8,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 48
 },
 {
  "id": 58,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 142,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 117,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 137,
  "Дашдондогийн ГАНБАТ - АН ": 122,
  "Пүрэвийн НҮРЗЭД - АН ": 101,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 109,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 11,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 32
 },
 {
  "id": 59,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 165,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 147,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 141,
  "Дашдондогийн ГАНБАТ - АН ": 217,
  "Пүрэвийн НҮРЗЭД - АН ": 216,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 181,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 32,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 67
 },
 {
  "id": 60,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 200,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 174,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 175,
  "Дашдондогийн ГАНБАТ - АН ": 179,
  "Пүрэвийн НҮРЗЭД - АН ": 185,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 190,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 9,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 15,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 42
 },
 {
  "id": 61,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 147,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 126,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 138,
  "Дашдондогийн ГАНБАТ - АН ": 107,
  "Пүрэвийн НҮРЗЭД - АН ": 89,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 97,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 16,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 0,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 28
 },
 {
  "id": 62,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 271,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 244,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 244,
  "Дашдондогийн ГАНБАТ - АН ": 199,
  "Пүрэвийн НҮРЗЭД - АН ": 179,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 154,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 13,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 13,
  "Очирын БАТСҮХ - ШЭ ": 18,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 5,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 52
 },
 {
  "id": 63,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 221,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 198,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 200,
  "Дашдондогийн ГАНБАТ - АН ": 153,
  "Пүрэвийн НҮРЗЭД - АН ": 169,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 124,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 10,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 38,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 13,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 16,
  "Очирын БАТСҮХ - ШЭ ": 12,
  "Маамын ГАНЗОРИГ - ШЭ ": 6,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 6,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 34
 },
 {
  "id": 64,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 172,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 160,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 139,
  "Дашдондогийн ГАНБАТ - АН ": 134,
  "Пүрэвийн НҮРЗЭД - АН ": 136,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 106,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 16,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 3,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 0,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 30
 },
 {
  "id": 65,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 106,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 105,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 105,
  "Дашдондогийн ГАНБАТ - АН ": 132,
  "Пүрэвийн НҮРЗЭД - АН ": 114,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 110,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 4,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 8,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 43
 },
 {
  "id": 66,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 239,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 225,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 208,
  "Дашдондогийн ГАНБАТ - АН ": 213,
  "Пүрэвийн НҮРЗЭД - АН ": 238,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 188,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 12,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 5,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 4,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 96
 },
 {
  "id": 67,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 164,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 133,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 143,
  "Дашдондогийн ГАНБАТ - АН ": 175,
  "Пүрэвийн НҮРЗЭД - АН ": 175,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 155,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 14,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 6,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 27
 },
 {
  "id": 68,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 67,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 96,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 85,
  "Дашдондогийн ГАНБАТ - АН ": 180,
  "Пүрэвийн НҮРЗЭД - АН ": 153,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 145,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 10,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 9,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 43
 },
 {
  "id": 69,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 129,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 162,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 101,
  "Дашдондогийн ГАНБАТ - АН ": 110,
  "Пүрэвийн НҮРЗЭД - АН ": 65,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 67,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 31,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 14,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 11,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 58
 },
 {
  "id": 70,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 125,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 172,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 109,
  "Дашдондогийн ГАНБАТ - АН ": 148,
  "Пүрэвийн НҮРЗЭД - АН ": 96,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 110,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 12,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 8,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 0,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 4,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 59
 },
 {
  "id": 71,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 179,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 202,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 141,
  "Дашдондогийн ГАНБАТ - АН ": 172,
  "Пүрэвийн НҮРЗЭД - АН ": 123,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 117,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 17,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 25,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 15,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 16,
  "Баярсайханы УУГАНБАЯР ": 6,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 65
 },
 {
  "id": 72,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 213,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 209,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 188,
  "Дашдондогийн ГАНБАТ - АН ": 201,
  "Пүрэвийн НҮРЗЭД - АН ": 199,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 174,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 16,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 10,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 9,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 14,
  "Очирын БАТСҮХ - ШЭ ": 20,
  "Маамын ГАНЗОРИГ - ШЭ ": 6,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 22
 },
 {
  "id": 73,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 181,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 172,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 179,
  "Дашдондогийн ГАНБАТ - АН ": 158,
  "Пүрэвийн НҮРЗЭД - АН ": 142,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 138,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 10,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 8,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 9,
  "Очирын БАТСҮХ - ШЭ ": 13,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 26
 },
 {
  "id": 74,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 256,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 229,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 233,
  "Дашдондогийн ГАНБАТ - АН ": 188,
  "Пүрэвийн НҮРЗЭД - АН ": 193,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 177,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 23,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 15,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 11,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 8,
  "Очирын БАТСҮХ - ШЭ ": 17,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 4,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 8,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 58
 },
 {
  "id": 75,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 150,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 143,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 140,
  "Дашдондогийн ГАНБАТ - АН ": 127,
  "Пүрэвийн НҮРЗЭД - АН ": 116,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 105,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 10,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 13,
  "Очирын БАТСҮХ - ШЭ ": 20,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 2,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 4,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 37
 },
 {
  "id": 76,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 95,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 50,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 77,
  "Дашдондогийн ГАНБАТ - АН ": 108,
  "Пүрэвийн НҮРЗЭД - АН ": 67,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 97,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 2,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 26,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 25
 },
 {
  "id": 77,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 135,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 82,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 102,
  "Дашдондогийн ГАНБАТ - АН ": 165,
  "Пүрэвийн НҮРЗЭД - АН ": 127,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 170,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 28,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 5,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 22
 },
 {
  "id": 78,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 181,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 112,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 122,
  "Дашдондогийн ГАНБАТ - АН ": 136,
  "Пүрэвийн НҮРЗЭД - АН ": 98,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 162,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 32,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 7
 },
 {
  "id": 79,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 118,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 97,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 100,
  "Дашдондогийн ГАНБАТ - АН ": 119,
  "Пүрэвийн НҮРЗЭД - АН ": 75,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 95,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 4,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 18,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 4,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 22
 },
 {
  "id": 80,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 104,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 57,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 77,
  "Дашдондогийн ГАНБАТ - АН ": 136,
  "Пүрэвийн НҮРЗЭД - АН ": 69,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 112,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 9,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 37,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 19
 },
 {
  "id": 81,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 108,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 115,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 100,
  "Дашдондогийн ГАНБАТ - АН ": 103,
  "Пүрэвийн НҮРЗЭД - АН ": 87,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 122,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 15,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 22,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 0,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 35
 },
 {
  "id": 82,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 166,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 126,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 121,
  "Дашдондогийн ГАНБАТ - АН ": 131,
  "Пүрэвийн НҮРЗЭД - АН ": 116,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 125,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 13,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 16,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 13,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 51
 },
 {
  "id": 83,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 123,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 129,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 119,
  "Дашдондогийн ГАНБАТ - АН ": 118,
  "Пүрэвийн НҮРЗЭД - АН ": 112,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 128,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 20,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 6,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 22,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 62
 },
 {
  "id": 84,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 131,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 124,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 104,
  "Дашдондогийн ГАНБАТ - АН ": 171,
  "Пүрэвийн НҮРЗЭД - АН ": 150,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 162,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 20,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 25,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 10,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 45
 },
 {
  "id": 85,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 242,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 176,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 192,
  "Дашдондогийн ГАНБАТ - АН ": 138,
  "Пүрэвийн НҮРЗЭД - АН ": 86,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 139,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 9,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 26,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 8,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 2,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 7,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 4,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 13
 },
 {
  "id": 86,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 320,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 212,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 233,
  "Дашдондогийн ГАНБАТ - АН ": 184,
  "Пүрэвийн НҮРЗЭД - АН ": 125,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 185,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 14,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 10,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 4,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 5,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 47
 },
 {
  "id": 87,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 165,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 109,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 127,
  "Дашдондогийн ГАНБАТ - АН ": 128,
  "Пүрэвийн НҮРЗЭД - АН ": 97,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 141,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 3,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 8,
  "Маамын ГАНЗОРИГ - ШЭ ": 1,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 6
 },
 {
  "id": 88,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 233,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 135,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 142,
  "Дашдондогийн ГАНБАТ - АН ": 149,
  "Пүрэвийн НҮРЗЭД - АН ": 114,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 176,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 3,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 20,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 11,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 7,
  "Очирын БАТСҮХ - ШЭ ": 15,
  "Маамын ГАНЗОРИГ - ШЭ ": 6,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 30
 },
 {
  "id": 89,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 248,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 168,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 195,
  "Дашдондогийн ГАНБАТ - АН ": 142,
  "Пүрэвийн НҮРЗЭД - АН ": 105,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 156,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 4,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 9,
  "Маамын ГАНЗОРИГ - ШЭ ": 5,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 3,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 9
 },
 {
  "id": 90,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 237,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 151,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 177,
  "Дашдондогийн ГАНБАТ - АН ": 158,
  "Пүрэвийн НҮРЗЭД - АН ": 109,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 180,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 19,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 10,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 14,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 6,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 4,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 9,
  "Батчулууны ОТГОНБАЯР ": 5,
  "Баярсайханы УУГАНБАЯР ": 7,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 16
 },
 {
  "id": 91,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 130,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 108,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 114,
  "Дашдондогийн ГАНБАТ - АН ": 82,
  "Пүрэвийн НҮРЗЭД - АН ": 42,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 53,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 0,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 5,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 26
 },
 {
  "id": 92,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 119,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 102,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 103,
  "Дашдондогийн ГАНБАТ - АН ": 74,
  "Пүрэвийн НҮРЗЭД - АН ": 52,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 67,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 1,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 3,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 0,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 19
 },
 {
  "id": 93,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 119,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 104,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 105,
  "Дашдондогийн ГАНБАТ - АН ": 87,
  "Пүрэвийн НҮРЗЭД - АН ": 63,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 64,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 1,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 4,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 2,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 0,
  "Очирын БАТСҮХ - ШЭ ": 2,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 20
 },
 {
  "id": 94,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 67,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 98,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 53,
  "Дашдондогийн ГАНБАТ - АН ": 140,
  "Пүрэвийн НҮРЗЭД - АН ": 100,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 102,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 0,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 5,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 3,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 33
 },
 {
  "id": 95,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 78,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 67,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 68,
  "Дашдондогийн ГАНБАТ - АН ": 67,
  "Пүрэвийн НҮРЗЭД - АН ": 44,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 48,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 1,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 9,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 5,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 0,
  "Очирын БАТСҮХ - ШЭ ": 0,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 1,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 11
 },
 {
  "id": 96,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 124,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 80,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 95,
  "Дашдондогийн ГАНБАТ - АН ": 150,
  "Пүрэвийн НҮРЗЭД - АН ": 124,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 154,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 2,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 4,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 1,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 0,
  "Очирын БАТСҮХ - ШЭ ": 0,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 8,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 22
 },
 {
  "id": 97,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 236,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 166,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 187,
  "Дашдондогийн ГАНБАТ - АН ": 295,
  "Пүрэвийн НҮРЗЭД - АН ": 239,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 263,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 11,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 13,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 8,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 7,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 6,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 23
 },
 {
  "id": 98,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 225,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 157,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 186,
  "Дашдондогийн ГАНБАТ - АН ": 183,
  "Пүрэвийн НҮРЗЭД - АН ": 176,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 193,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 7,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 19,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 3,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 23,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 2,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 17
 },
 {
  "id": 99,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 62,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 43,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 42,
  "Дашдондогийн ГАНБАТ - АН ": 97,
  "Пүрэвийн НҮРЗЭД - АН ": 87,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 106,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 4,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 7,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 0,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 0,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 0,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 14,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 0,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 4
 },
 {
  "id": 100,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 217,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 193,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 192,
  "Дашдондогийн ГАНБАТ - АН ": 132,
  "Пүрэвийн НҮРЗЭД - АН ": 128,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 125,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 47,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 3,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 9,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 6,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 1,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 0,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 44
 },
 {
  "id": 101,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 175,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 160,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 154,
  "Дашдондогийн ГАНБАТ - АН ": 187,
  "Пүрэвийн НҮРЗЭД - АН ": 192,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 188,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 10,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 33,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 12,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 5,
  "Очирын БАТСҮХ - ШЭ ": 8,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 34
 },
 {
  "id": 102,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 218,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 200,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 193,
  "Дашдондогийн ГАНБАТ - АН ": 138,
  "Пүрэвийн НҮРЗЭД - АН ": 128,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 133,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 5,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 33,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 5,
  "Маамын ГАНЗОРИГ - ШЭ ": 3,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 0,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 2,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 20
 },
 {
  "id": 103,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 221,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 207,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 197,
  "Дашдондогийн ГАНБАТ - АН ": 174,
  "Пүрэвийн НҮРЗЭД - АН ": 174,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 173,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 4,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 15,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 7,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 6,
  "Очирын БАТСҮХ - ШЭ ": 7,
  "Маамын ГАНЗОРИГ - ШЭ ": 5,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 1,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 1,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 2,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 1,
  "Батчулууны ОТГОНБАЯР ": 0,
  "Баярсайханы УУГАНБАЯР ": 3,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 6
 },
 {
  "id": 104,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 179,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 164,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 163,
  "Дашдондогийн ГАНБАТ - АН ": 118,
  "Пүрэвийн НҮРЗЭД - АН ": 105,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 107,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 6,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 28,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 4,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 1,
  "Очирын БАТСҮХ - ШЭ ": 1,
  "Маамын ГАНЗОРИГ - ШЭ ": 2,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 2,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 0,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 0,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 1,
  "Баасанжавын АНХБАЯР ": 3,
  "Батчулууны ОТГОНБАЯР ": 3,
  "Баярсайханы УУГАНБАЯР ": 1,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 9
 },
 {
  "id": 105,
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 208,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 168,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 167,
  "Дашдондогийн ГАНБАТ - АН ": 205,
  "Пүрэвийн НҮРЗЭД - АН ": 198,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 224,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 8,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 90,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 8,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 4,
  "Очирын БАТСҮХ - ШЭ ": 6,
  "Маамын ГАНЗОРИГ - ШЭ ": 4,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 0,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 3,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 3,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 2,
  "Баасанжавын АНХБАЯР ": 2,
  "Батчулууны ОТГОНБАЯР ": 2,
  "Баярсайханы УУГАНБАЯР ": 5,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 19
 },
 {
  "id": "total",
  "Гомбожавын ЗАНДАНШАТАР - МАН ": 24029,
  "Лхагваагийн ЭЛДЭВ-ОЧИР - МАН ": 18080,
  "Батмөнхийн БАТЦЭЦЭГ - МАН ": 18790,
  "Дашдондогийн ГАНБАТ - АН ": 21427,
  "Пүрэвийн НҮРЗЭД - АН ": 18490,
  "Амгалангийн АДЪЯАСҮРЭН - АН ": 19076,
  "Жамбалын ГАНЗУЛ - ТБЭ ": 1510,
  "Эрдэнэ-Очирын СОДНОМЖАМЦ - ТБЭ ": 4517,
  "Гунаажавын ЦЭВЭЭНЖАВ - ТБЭ ": 898,
  "Мандахын ЦЭНДЖАВ - ШЭ ": 780,
  "Очирын БАТСҮХ - ШЭ ": 900,
  "Маамын ГАНЗОРИГ - ШЭ ": 315,
  "Жагваралын ТӨМӨРХУЯГ - ЗХЭЭ ": 313,
  "Мягмарын АРИУНТУЯА - ЗХЭЭ ": 204,
  "Пүрэвсүрэнгийн ОЧИРПҮРЭВ - ЗОН ": 143,
  "Чимэддоржийн ГАНТУЛГА - АТОЗН ": 168,
  "Баасанжавын АНХБАЯР ": 526,
  "Батчулууны ОТГОНБАЯР ": 372,
  "Баярсайханы УУГАНБАЯР ": 246,
  "Даваагийн МӨНХ-ЭРДЭНЭ ": 4948
 }
]