export const election2020Attendance = [
    {
        "id": 1,
        "Аймаг, дүүргийн нэр ": "Архангай",
        "Мандатын тоо": 3,
        "Хэсгийн тоо": 105,
        "Үндсэн сонгогчийн тоо": 61194,
        "Түр хасав-аас сэргээсэн": 33,
        "Нийт сонгогчийн тоо": 61227,
        "Санал өгсөн хүний тоо": 45469,
        "Хурууны хээгээр": 41607,
        "Регистрийн дугаараар": 2953,
        "Зөөврийн хайрцагаар": 876,
        "Эрэгтэй": 21683,
        "Эмэгтэй": 23786,
        "18-25 нас": 7106,
        "26-40 нас": 14944,
        "41-55 нас": 14369,
        "56 ба түүнээс дээш": 9050,
        "Үндсэн сонгогчоос %": 0.7430303624538354,
        "Нийт \nсонгогчоос %": 0.7426298855080276
       },
       {
        "id": 2,
        "Аймаг, дүүргийн нэр ": "Баянхонгор",
        "Мандатын тоо": 3,
        "Хэсгийн тоо": 105,
        "Үндсэн сонгогчийн тоо": 56574,
        "Түр хасав-аас сэргээсэн": 36,
        "Нийт сонгогчийн тоо": 56610,
        "Санал өгсөн хүний тоо": 45318,
        "Хурууны хээгээр": 42950,
        "Регистрийн дугаараар": 1326,
        "Зөөврийн хайрцагаар": 1006,
        "Эрэгтэй": 20957,
        "Эмэгтэй": 24361,
        "18-25 нас": 8200,
        "26-40 нас": 16295,
        "41-55 нас": 12962,
        "56 ба түүнээс дээш": 7861,
        "Үндсэн сонгогчоос %": 0.8010393466963622,
        "Нийт \nсонгогчоос %": 0.8005299417064123
       },
       {
        "id": 3,
        "Аймаг, дүүргийн нэр ": "Өвөрхангай",
        "Мандатын тоо": 3,
        "Хэсгийн тоо": 106,
        "Үндсэн сонгогчийн тоо": 74367,
        "Түр хасав-аас сэргээсэн": 53,
        "Нийт сонгогчийн тоо": 74420,
        "Санал өгсөн хүний тоо": 53286,
        "Хурууны хээгээр": 50221,
        "Регистрийн дугаараар": 1525,
        "Зөөврийн хайрцагаар": 1487,
        "Эрэгтэй": 24240,
        "Эмэгтэй": 29046,
        "18-25 нас": 8624,
        "26-40 нас": 17474,
        "41-55 нас": 16364,
        "56 ба түүнээс дээш": 10824,
        "Үндсэн сонгогчоос %": 0.7165274920327565,
        "Нийт \nсонгогчоос %": 0.7160171996775061
       }
    ]