export const election2020CandidatesUvurkhangai =[
 {
  "id": 1,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 881,
  "Содномын ЧИНЗОРИГ - МАН ": 818,
  "Гочоогийн ГАНБОЛД - МАН ": 614,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 376,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 305,
  "Чимиддоржийн ДАВААБАЯР - АН ": 371,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 65,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 91,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 13,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 267,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 36,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 16,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 10,
  "Төмөрбаатарын ХАСБААТАР ": 58
 },
 {
  "id": 2,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 689,
  "Содномын ЧИНЗОРИГ - МАН ": 647,
  "Гочоогийн ГАНБОЛД - МАН ": 474,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 255,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 222,
  "Чимиддоржийн ДАВААБАЯР - АН ": 263,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 44,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 95,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 14,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 229,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 34,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 7,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 5,
  "Төмөрбаатарын ХАСБААТАР ": 43
 },
 {
  "id": 3,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 638,
  "Содномын ЧИНЗОРИГ - МАН ": 608,
  "Гочоогийн ГАНБОЛД - МАН ": 431,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 265,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 211,
  "Чимиддоржийн ДАВААБАЯР - АН ": 248,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 44,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 91,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 218,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 43,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 7,
  "Төмөрбаатарын ХАСБААТАР ": 30
 },
 {
  "id": 4,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 940,
  "Содномын ЧИНЗОРИГ - МАН ": 898,
  "Гочоогийн ГАНБОЛД - МАН ": 643,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 384,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 295,
  "Чимиддоржийн ДАВААБАЯР - АН ": 354,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 60,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 180,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 15,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 285,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 49,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 38
 },
 {
  "id": 5,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 992,
  "Содномын ЧИНЗОРИГ - МАН ": 878,
  "Гочоогийн ГАНБОЛД - МАН ": 735,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 435,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 290,
  "Чимиддоржийн ДАВААБАЯР - АН ": 400,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 60,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 137,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 19,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 229,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 64,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 8,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 9,
  "Төмөрбаатарын ХАСБААТАР ": 55
 },
 {
  "id": 6,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 696,
  "Содномын ЧИНЗОРИГ - МАН ": 682,
  "Гочоогийн ГАНБОЛД - МАН ": 500,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 286,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 235,
  "Чимиддоржийн ДАВААБАЯР - АН ": 270,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 50,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 123,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 21,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 235,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 21,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 11,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 16,
  "Төмөрбаатарын ХАСБААТАР ": 28
 },
 {
  "id": 7,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 684,
  "Содномын ЧИНЗОРИГ - МАН ": 614,
  "Гочоогийн ГАНБОЛД - МАН ": 460,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 360,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 287,
  "Чимиддоржийн ДАВААБАЯР - АН ": 372,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 32,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 86,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 20,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 229,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 29,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 7,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 7,
  "Төмөрбаатарын ХАСБААТАР ": 35
 },
 {
  "id": 8,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 855,
  "Содномын ЧИНЗОРИГ - МАН ": 851,
  "Гочоогийн ГАНБОЛД - МАН ": 596,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 397,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 272,
  "Чимиддоржийн ДАВААБАЯР - АН ": 342,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 45,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 164,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 17,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 270,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 51,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 9,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 12,
  "Төмөрбаатарын ХАСБААТАР ": 55
 },
 {
  "id": 9,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 648,
  "Содномын ЧИНЗОРИГ - МАН ": 609,
  "Гочоогийн ГАНБОЛД - МАН ": 440,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 330,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 270,
  "Чимиддоржийн ДАВААБАЯР - АН ": 342,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 67,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 120,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 26,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 260,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 52,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 9,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 10,
  "Төмөрбаатарын ХАСБААТАР ": 33
 },
 {
  "id": 10,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 857,
  "Содномын ЧИНЗОРИГ - МАН ": 848,
  "Гочоогийн ГАНБОЛД - МАН ": 623,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 313,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 292,
  "Чимиддоржийн ДАВААБАЯР - АН ": 298,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 75,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 154,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 37,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 237,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 27,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 9,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 42
 },
 {
  "id": 11,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 476,
  "Содномын ЧИНЗОРИГ - МАН ": 458,
  "Гочоогийн ГАНБОЛД - МАН ": 330,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 168,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 146,
  "Чимиддоржийн ДАВААБАЯР - АН ": 174,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 39,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 78,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 20,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 111,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 18,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 19
 },
 {
  "id": 12,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 374,
  "Содномын ЧИНЗОРИГ - МАН ": 356,
  "Гочоогийн ГАНБОЛД - МАН ": 263,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 126,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 115,
  "Чимиддоржийн ДАВААБАЯР - АН ": 137,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 30,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 44,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 102,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 15,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 5,
  "Төмөрбаатарын ХАСБААТАР ": 14
 },
 {
  "id": 13,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 785,
  "Содномын ЧИНЗОРИГ - МАН ": 686,
  "Гочоогийн ГАНБОЛД - МАН ": 572,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 361,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 251,
  "Чимиддоржийн ДАВААБАЯР - АН ": 314,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 91,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 222,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 56,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 9,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 7,
  "Төмөрбаатарын ХАСБААТАР ": 37
 },
 {
  "id": 14,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 180,
  "Содномын ЧИНЗОРИГ - МАН ": 204,
  "Гочоогийн ГАНБОЛД - МАН ": 166,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 131,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 107,
  "Чимиддоржийн ДАВААБАЯР - АН ": 153,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 0,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 47,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 15,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 114,
  "Содномын ЧИНЗОРИГ - МАН ": 155,
  "Гочоогийн ГАНБОЛД - МАН ": 108,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 156,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 141,
  "Чимиддоржийн ДАВААБАЯР - АН ": 210,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 8,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 55,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 16,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 163,
  "Содномын ЧИНЗОРИГ - МАН ": 196,
  "Гочоогийн ГАНБОЛД - МАН ": 153,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 154,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 161,
  "Чимиддоржийн ДАВААБАЯР - АН ": 199,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 5,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 46,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 17,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 144,
  "Содномын ЧИНЗОРИГ - МАН ": 182,
  "Гочоогийн ГАНБОЛД - МАН ": 146,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 159,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 157,
  "Чимиддоржийн ДАВААБАЯР - АН ": 235,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 10,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 9,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 30,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 18,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 436,
  "Содномын ЧИНЗОРИГ - МАН ": 499,
  "Гочоогийн ГАНБОЛД - МАН ": 359,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 253,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 250,
  "Чимиддоржийн ДАВААБАЯР - АН ": 193,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 33,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 72,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 13,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 234,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 23,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 8,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 13,
  "Төмөрбаатарын ХАСБААТАР ": 14
 },
 {
  "id": 19,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 374,
  "Содномын ЧИНЗОРИГ - МАН ": 393,
  "Гочоогийн ГАНБОЛД - МАН ": 302,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 269,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 248,
  "Чимиддоржийн ДАВААБАЯР - АН ": 219,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 21,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 31,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 163,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 7,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 20,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 407,
  "Содномын ЧИНЗОРИГ - МАН ": 457,
  "Гочоогийн ГАНБОЛД - МАН ": 326,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 279,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 297,
  "Чимиддоржийн ДАВААБАЯР - АН ": 255,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 18,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 47,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 154,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 13,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 8,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 21,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 468,
  "Содномын ЧИНЗОРИГ - МАН ": 493,
  "Гочоогийн ГАНБОЛД - МАН ": 374,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 163,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 178,
  "Чимиддоржийн ДАВААБАЯР - АН ": 158,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 28,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 150,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 12,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 22,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 306,
  "Содномын ЧИНЗОРИГ - МАН ": 326,
  "Гочоогийн ГАНБОЛД - МАН ": 237,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 144,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 113,
  "Чимиддоржийн ДАВААБАЯР - АН ": 116,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 15,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 45,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 94,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 9
 },
 {
  "id": 23,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 217,
  "Содномын ЧИНЗОРИГ - МАН ": 262,
  "Гочоогийн ГАНБОЛД - МАН ": 150,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 87,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 92,
  "Чимиддоржийн ДАВААБАЯР - АН ": 98,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 16,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 26,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 101,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 24,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 188,
  "Содномын ЧИНЗОРИГ - МАН ": 237,
  "Гочоогийн ГАНБОЛД - МАН ": 133,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 49,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 68,
  "Чимиддоржийн ДАВААБАЯР - АН ": 61,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 11,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 66,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 3,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 99,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 13,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 5,
  "Төмөрбаатарын ХАСБААТАР ": 16
 },
 {
  "id": 25,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 145,
  "Содномын ЧИНЗОРИГ - МАН ": 170,
  "Гочоогийн ГАНБОЛД - МАН ": 121,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 45,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 49,
  "Чимиддоржийн ДАВААБАЯР - АН ": 56,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 11,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 20,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 34,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 26,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 130,
  "Содномын ЧИНЗОРИГ - МАН ": 167,
  "Гочоогийн ГАНБОЛД - МАН ": 99,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 86,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 87,
  "Чимиддоржийн ДАВААБАЯР - АН ": 87,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 21,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 39,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 27,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 160,
  "Содномын ЧИНЗОРИГ - МАН ": 196,
  "Гочоогийн ГАНБОЛД - МАН ": 114,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 123,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 109,
  "Чимиддоржийн ДАВААБАЯР - АН ": 143,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 13,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 30,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 85,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 28,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 158,
  "Содномын ЧИНЗОРИГ - МАН ": 192,
  "Гочоогийн ГАНБОЛД - МАН ": 136,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 103,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 104,
  "Чимиддоржийн ДАВААБАЯР - АН ": 118,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 4,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 27,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 29,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 29,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 113,
  "Содномын ЧИНЗОРИГ - МАН ": 164,
  "Гочоогийн ГАНБОЛД - МАН ": 78,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 93,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 95,
  "Чимиддоржийн ДАВААБАЯР - АН ": 102,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 27,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 8,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 0,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 144,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 9,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 30,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 122,
  "Содномын ЧИНЗОРИГ - МАН ": 170,
  "Гочоогийн ГАНБОЛД - МАН ": 103,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 110,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 139,
  "Чимиддоржийн ДАВААБАЯР - АН ": 98,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 28,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 5,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 127,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 31,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 140,
  "Содномын ЧИНЗОРИГ - МАН ": 187,
  "Гочоогийн ГАНБОЛД - МАН ": 131,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 137,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 121,
  "Чимиддоржийн ДАВААБАЯР - АН ": 104,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 31,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 3,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 101,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 32,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 125,
  "Содномын ЧИНЗОРИГ - МАН ": 188,
  "Гочоогийн ГАНБОЛД - МАН ": 102,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 63,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 37,
  "Чимиддоржийн ДАВААБАЯР - АН ": 67,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 44,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 12,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 152,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 33,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 149,
  "Содномын ЧИНЗОРИГ - МАН ": 258,
  "Гочоогийн ГАНБОЛД - МАН ": 139,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 213,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 163,
  "Чимиддоржийн ДАВААБАЯР - АН ": 181,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 54,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 18,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 265,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 7
 },
 {
  "id": 34,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 181,
  "Содномын ЧИНЗОРИГ - МАН ": 232,
  "Гочоогийн ГАНБОЛД - МАН ": 159,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 138,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 126,
  "Чимиддоржийн ДАВААБАЯР - АН ": 121,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 21,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 29,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 89,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 7,
  "Төмөрбаатарын ХАСБААТАР ": 10
 },
 {
  "id": 35,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 197,
  "Содномын ЧИНЗОРИГ - МАН ": 237,
  "Гочоогийн ГАНБОЛД - МАН ": 171,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 134,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 135,
  "Чимиддоржийн ДАВААБАЯР - АН ": 133,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 13,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 29,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 58,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 13
 },
 {
  "id": 36,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 160,
  "Содномын ЧИНЗОРИГ - МАН ": 203,
  "Гочоогийн ГАНБОЛД - МАН ": 143,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 169,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 187,
  "Чимиддоржийн ДАВААБАЯР - АН ": 175,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 15,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 52,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 37,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 189,
  "Содномын ЧИНЗОРИГ - МАН ": 220,
  "Гочоогийн ГАНБОЛД - МАН ": 164,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 162,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 168,
  "Чимиддоржийн ДАВААБАЯР - АН ": 161,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 5,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 10,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 42,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 12
 },
 {
  "id": 38,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 241,
  "Содномын ЧИНЗОРИГ - МАН ": 316,
  "Гочоогийн ГАНБОЛД - МАН ": 195,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 161,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 167,
  "Чимиддоржийн ДАВААБАЯР - АН ": 145,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 18,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 35,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 96,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 7,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 8
 },
 {
  "id": 39,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 145,
  "Содномын ЧИНЗОРИГ - МАН ": 199,
  "Гочоогийн ГАНБОЛД - МАН ": 115,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 72,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 65,
  "Чимиддоржийн ДАВААБАЯР - АН ": 67,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 19,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 33,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 99,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 14
 },
 {
  "id": 40,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 179,
  "Содномын ЧИНЗОРИГ - МАН ": 215,
  "Гочоогийн ГАНБОЛД - МАН ": 155,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 149,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 159,
  "Чимиддоржийн ДАВААБАЯР - АН ": 149,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 12,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 23,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 44,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 12
 },
 {
  "id": 41,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 125,
  "Содномын ЧИНЗОРИГ - МАН ": 181,
  "Гочоогийн ГАНБОЛД - МАН ": 83,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 102,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 121,
  "Чимиддоржийн ДАВААБАЯР - АН ": 97,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 16,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 35,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 45,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 42,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 120,
  "Содномын ЧИНЗОРИГ - МАН ": 170,
  "Гочоогийн ГАНБОЛД - МАН ": 92,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 172,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 184,
  "Чимиддоржийн ДАВААБАЯР - АН ": 164,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 16,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 32,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 61,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 8
 },
 {
  "id": 43,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 116,
  "Содномын ЧИНЗОРИГ - МАН ": 177,
  "Гочоогийн ГАНБОЛД - МАН ": 86,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 124,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 128,
  "Чимиддоржийн ДАВААБАЯР - АН ": 96,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 13,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 40,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 41,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 9,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 44,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 106,
  "Содномын ЧИНЗОРИГ - МАН ": 144,
  "Гочоогийн ГАНБОЛД - МАН ": 85,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 109,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 120,
  "Чимиддоржийн ДАВААБАЯР - АН ": 111,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 13,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 37,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 44,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 45,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 96,
  "Содномын ЧИНЗОРИГ - МАН ": 111,
  "Гочоогийн ГАНБОЛД - МАН ": 78,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 108,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 104,
  "Чимиддоржийн ДАВААБАЯР - АН ": 96,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 30,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 28,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 46,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 309,
  "Содномын ЧИНЗОРИГ - МАН ": 355,
  "Гочоогийн ГАНБОЛД - МАН ": 251,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 159,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 152,
  "Чимиддоржийн ДАВААБАЯР - АН ": 183,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 35,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 75,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 19,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 47,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 320,
  "Содномын ЧИНЗОРИГ - МАН ": 377,
  "Гочоогийн ГАНБОЛД - МАН ": 271,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 193,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 179,
  "Чимиддоржийн ДАВААБАЯР - АН ": 206,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 19,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 40,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 92,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 11,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 48,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 143,
  "Содномын ЧИНЗОРИГ - МАН ": 182,
  "Гочоогийн ГАНБОЛД - МАН ": 114,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 71,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 90,
  "Чимиддоржийн ДАВААБАЯР - АН ": 94,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 24,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 26,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 62,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 49,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 196,
  "Содномын ЧИНЗОРИГ - МАН ": 227,
  "Гочоогийн ГАНБОЛД - МАН ": 169,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 98,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 119,
  "Чимиддоржийн ДАВААБАЯР - АН ": 112,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 10,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 24,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 42,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 50,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 172,
  "Содномын ЧИНЗОРИГ - МАН ": 208,
  "Гочоогийн ГАНБОЛД - МАН ": 145,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 103,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 118,
  "Чимиддоржийн ДАВААБАЯР - АН ": 96,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 21,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 28,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 58,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 51,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 184,
  "Содномын ЧИНЗОРИГ - МАН ": 231,
  "Гочоогийн ГАНБОЛД - МАН ": 152,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 108,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 147,
  "Чимиддоржийн ДАВААБАЯР - АН ": 129,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 13,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 29,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 11,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 48,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 52,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 188,
  "Содномын ЧИНЗОРИГ - МАН ": 198,
  "Гочоогийн ГАНБОЛД - МАН ": 158,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 221,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 210,
  "Чимиддоржийн ДАВААБАЯР - АН ": 227,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 9,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 45,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 53,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 188,
  "Содномын ЧИНЗОРИГ - МАН ": 204,
  "Гочоогийн ГАНБОЛД - МАН ": 138,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 99,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 123,
  "Чимиддоржийн ДАВААБАЯР - АН ": 123,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 7,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 56,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 54,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 203,
  "Содномын ЧИНЗОРИГ - МАН ": 223,
  "Гочоогийн ГАНБОЛД - МАН ": 166,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 103,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 112,
  "Чимиддоржийн ДАВААБАЯР - АН ": 101,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 8,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 11,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 75,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 12,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 55,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 237,
  "Содномын ЧИНЗОРИГ - МАН ": 263,
  "Гочоогийн ГАНБОЛД - МАН ": 200,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 168,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 171,
  "Чимиддоржийн ДАВААБАЯР - АН ": 154,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 26,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 26,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 60,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 9,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 56,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 147,
  "Содномын ЧИНЗОРИГ - МАН ": 152,
  "Гочоогийн ГАНБОЛД - МАН ": 109,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 75,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 94,
  "Чимиддоржийн ДАВААБАЯР - АН ": 74,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 5,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 12,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 37,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 57,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 150,
  "Содномын ЧИНЗОРИГ - МАН ": 166,
  "Гочоогийн ГАНБОЛД - МАН ": 108,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 79,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 81,
  "Чимиддоржийн ДАВААБАЯР - АН ": 79,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 1,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 9,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 46,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 58,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 190,
  "Содномын ЧИНЗОРИГ - МАН ": 204,
  "Гочоогийн ГАНБОЛД - МАН ": 246,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 102,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 82,
  "Чимиддоржийн ДАВААБАЯР - АН ": 100,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 11,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 47,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 24
 },
 {
  "id": 59,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 354,
  "Содномын ЧИНЗОРИГ - МАН ": 408,
  "Гочоогийн ГАНБОЛД - МАН ": 383,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 144,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 126,
  "Чимиддоржийн ДАВААБАЯР - АН ": 178,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 31,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 91,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 25
 },
 {
  "id": 60,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 207,
  "Содномын ЧИНЗОРИГ - МАН ": 234,
  "Гочоогийн ГАНБОЛД - МАН ": 227,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 110,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 110,
  "Чимиддоржийн ДАВААБАЯР - АН ": 113,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 5,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 9,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 32,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 5,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 61,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 246,
  "Содномын ЧИНЗОРИГ - МАН ": 300,
  "Гочоогийн ГАНБОЛД - МАН ": 346,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 182,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 149,
  "Чимиддоржийн ДАВААБАЯР - АН ": 208,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 30,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 108,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 32
 },
 {
  "id": 62,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 158,
  "Содномын ЧИНЗОРИГ - МАН ": 231,
  "Гочоогийн ГАНБОЛД - МАН ": 137,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 82,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 118,
  "Чимиддоржийн ДАВААБАЯР - АН ": 106,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 8,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 68,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 39,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 7,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 63,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 219,
  "Содномын ЧИНЗОРИГ - МАН ": 263,
  "Гочоогийн ГАНБОЛД - МАН ": 177,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 51,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 76,
  "Чимиддоржийн ДАВААБАЯР - АН ": 73,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 8,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 55,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 33,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 64,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 135,
  "Содномын ЧИНЗОРИГ - МАН ": 179,
  "Гочоогийн ГАНБОЛД - МАН ": 106,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 83,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 96,
  "Чимиддоржийн ДАВААБАЯР - АН ": 105,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 10,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 67,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 24,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 65,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 114,
  "Содномын ЧИНЗОРИГ - МАН ": 161,
  "Гочоогийн ГАНБОЛД - МАН ": 82,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 111,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 109,
  "Чимиддоржийн ДАВААБАЯР - АН ": 112,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 41,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 31,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 66,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 134,
  "Содномын ЧИНЗОРИГ - МАН ": 181,
  "Гочоогийн ГАНБОЛД - МАН ": 109,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 65,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 84,
  "Чимиддоржийн ДАВААБАЯР - АН ": 80,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 25,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 74,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 10,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 49,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 67,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 165,
  "Содномын ЧИНЗОРИГ - МАН ": 222,
  "Гочоогийн ГАНБОЛД - МАН ": 134,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 134,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 124,
  "Чимиддоржийн ДАВААБАЯР - АН ": 95,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 18,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 82,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 70,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 68,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 203,
  "Содномын ЧИНЗОРИГ - МАН ": 243,
  "Гочоогийн ГАНБОЛД - МАН ": 170,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 79,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 100,
  "Чимиддоржийн ДАВААБАЯР - АН ": 61,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 11,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 73,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 48,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 69,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 183,
  "Содномын ЧИНЗОРИГ - МАН ": 239,
  "Гочоогийн ГАНБОЛД - МАН ": 139,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 117,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 95,
  "Чимиддоржийн ДАВААБАЯР - АН ": 101,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 101,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 3,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 40,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 70,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 160,
  "Содномын ЧИНЗОРИГ - МАН ": 178,
  "Гочоогийн ГАНБОЛД - МАН ": 133,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 146,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 157,
  "Чимиддоржийн ДАВААБАЯР - АН ": 125,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 21,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 88,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 0,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 30,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 71,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 185,
  "Содномын ЧИНЗОРИГ - МАН ": 173,
  "Гочоогийн ГАНБОЛД - МАН ": 118,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 135,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 143,
  "Чимиддоржийн ДАВААБАЯР - АН ": 131,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 16,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 16,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 69,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 11,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 72,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 149,
  "Содномын ЧИНЗОРИГ - МАН ": 127,
  "Гочоогийн ГАНБОЛД - МАН ": 94,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 79,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 62,
  "Чимиддоржийн ДАВААБАЯР - АН ": 64,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 14,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 5,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 26,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 14,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 73,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 310,
  "Содномын ЧИНЗОРИГ - МАН ": 304,
  "Гочоогийн ГАНБОЛД - МАН ": 253,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 230,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 220,
  "Чимиддоржийн ДАВААБАЯР - АН ": 228,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 12,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 45,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 74,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 184,
  "Содномын ЧИНЗОРИГ - МАН ": 178,
  "Гочоогийн ГАНБОЛД - МАН ": 97,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 109,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 90,
  "Чимиддоржийн ДАВААБАЯР - АН ": 87,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 21,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 3,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 76,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 75,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 107,
  "Содномын ЧИНЗОРИГ - МАН ": 102,
  "Гочоогийн ГАНБОЛД - МАН ": 60,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 74,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 69,
  "Чимиддоржийн ДАВААБАЯР - АН ": 73,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 6,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 8,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 53,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 76,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 175,
  "Содномын ЧИНЗОРИГ - МАН ": 197,
  "Гочоогийн ГАНБОЛД - МАН ": 156,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 162,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 158,
  "Чимиддоржийн ДАВААБАЯР - АН ": 162,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 7,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 11,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 29,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 77,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 214,
  "Содномын ЧИНЗОРИГ - МАН ": 237,
  "Гочоогийн ГАНБОЛД - МАН ": 181,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 79,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 71,
  "Чимиддоржийн ДАВААБАЯР - АН ": 69,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 11,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 14,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 3,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 50,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 78,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 108,
  "Содномын ЧИНЗОРИГ - МАН ": 122,
  "Гочоогийн ГАНБОЛД - МАН ": 92,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 127,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 125,
  "Чимиддоржийн ДАВААБАЯР - АН ": 114,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 7,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 13,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 38,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 79,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 167,
  "Содномын ЧИНЗОРИГ - МАН ": 176,
  "Гочоогийн ГАНБОЛД - МАН ": 143,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 54,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 54,
  "Чимиддоржийн ДАВААБАЯР - АН ": 53,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 8,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 7,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 19,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 0,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 0
 },
 {
  "id": 80,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 120,
  "Содномын ЧИНЗОРИГ - МАН ": 144,
  "Гочоогийн ГАНБОЛД - МАН ": 104,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 123,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 125,
  "Чимиддоржийн ДАВААБАЯР - АН ": 129,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 9,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 13,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 3,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 44,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 81,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 391,
  "Содномын ЧИНЗОРИГ - МАН ": 468,
  "Гочоогийн ГАНБОЛД - МАН ": 319,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 509,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 356,
  "Чимиддоржийн ДАВААБАЯР - АН ": 381,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 24,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 49,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 17,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 265,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 8,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 14,
  "Төмөрбаатарын ХАСБААТАР ": 14
 },
 {
  "id": 82,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 375,
  "Содномын ЧИНЗОРИГ - МАН ": 435,
  "Гочоогийн ГАНБОЛД - МАН ": 349,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 536,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 465,
  "Чимиддоржийн ДАВААБАЯР - АН ": 465,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 34,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 40,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 26,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 205,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 20,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 20
 },
 {
  "id": 83,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 526,
  "Содномын ЧИНЗОРИГ - МАН ": 584,
  "Гочоогийн ГАНБОЛД - МАН ": 451,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 417,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 296,
  "Чимиддоржийн ДАВААБАЯР - АН ": 316,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 46,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 56,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 18,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 213,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 10,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 8,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 8,
  "Төмөрбаатарын ХАСБААТАР ": 27
 },
 {
  "id": 84,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 602,
  "Содномын ЧИНЗОРИГ - МАН ": 612,
  "Гочоогийн ГАНБОЛД - МАН ": 527,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 562,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 450,
  "Чимиддоржийн ДАВААБАЯР - АН ": 450,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 36,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 65,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 20,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 156,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 7,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 5,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 6
 },
 {
  "id": 85,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 173,
  "Содномын ЧИНЗОРИГ - МАН ": 161,
  "Гочоогийн ГАНБОЛД - МАН ": 129,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 147,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 142,
  "Чимиддоржийн ДАВААБАЯР - АН ": 154,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 5,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 6,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 3,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 27,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 86,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 252,
  "Содномын ЧИНЗОРИГ - МАН ": 237,
  "Гочоогийн ГАНБОЛД - МАН ": 173,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 209,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 165,
  "Чимиддоржийн ДАВААБАЯР - АН ": 179,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 7,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 12,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 12,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 46,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 87,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 179,
  "Содномын ЧИНЗОРИГ - МАН ": 161,
  "Гочоогийн ГАНБОЛД - МАН ": 118,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 150,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 134,
  "Чимиддоржийн ДАВААБАЯР - АН ": 142,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 8,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 10,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 42,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 4,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 88,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 208,
  "Содномын ЧИНЗОРИГ - МАН ": 195,
  "Гочоогийн ГАНБОЛД - МАН ": 148,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 77,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 64,
  "Чимиддоржийн ДАВААБАЯР - АН ": 77,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 7,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 9,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 36,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 89,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 205,
  "Содномын ЧИНЗОРИГ - МАН ": 196,
  "Гочоогийн ГАНБОЛД - МАН ": 127,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 40,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 49,
  "Чимиддоржийн ДАВААБАЯР - АН ": 60,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 1,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 3,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 0,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 29,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 1,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 90,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 252,
  "Содномын ЧИНЗОРИГ - МАН ": 225,
  "Гочоогийн ГАНБОЛД - МАН ": 193,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 128,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 108,
  "Чимиддоржийн ДАВААБАЯР - АН ": 112,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 2,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 9,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 1,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 24,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": 91,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 362,
  "Содномын ЧИНЗОРИГ - МАН ": 393,
  "Гочоогийн ГАНБОЛД - МАН ": 291,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 141,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 176,
  "Чимиддоржийн ДАВААБАЯР - АН ": 148,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 51,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 72,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 15,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 103,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 24,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 18,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 18
 },
 {
  "id": 92,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 399,
  "Содномын ЧИНЗОРИГ - МАН ": 426,
  "Гочоогийн ГАНБОЛД - МАН ": 298,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 184,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 222,
  "Чимиддоржийн ДАВААБАЯР - АН ": 196,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 57,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 92,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 28,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 123,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 14,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 13,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 6,
  "Төмөрбаатарын ХАСБААТАР ": 24
 },
 {
  "id": 93,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 599,
  "Содномын ЧИНЗОРИГ - МАН ": 652,
  "Гочоогийн ГАНБОЛД - МАН ": 464,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 339,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 408,
  "Чимиддоржийн ДАВААБАЯР - АН ": 363,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 59,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 111,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 28,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 162,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 27,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 43,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 29
 },
 {
  "id": 94,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 410,
  "Содномын ЧИНЗОРИГ - МАН ": 436,
  "Гочоогийн ГАНБОЛД - МАН ": 334,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 197,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 234,
  "Чимиддоржийн ДАВААБАЯР - АН ": 213,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 37,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 71,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 19,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 120,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 12,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 13,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 95,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 282,
  "Содномын ЧИНЗОРИГ - МАН ": 313,
  "Гочоогийн ГАНБОЛД - МАН ": 223,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 196,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 212,
  "Чимиддоржийн ДАВААБАЯР - АН ": 198,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 53,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 64,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 29,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 79,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 16,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 15,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 96,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 146,
  "Содномын ЧИНЗОРИГ - МАН ": 157,
  "Гочоогийн ГАНБОЛД - МАН ": 157,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 149,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 161,
  "Чимиддоржийн ДАВААБАЯР - АН ": 146,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 21,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 9,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 69,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 7,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 0,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 97,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 193,
  "Содномын ЧИНЗОРИГ - МАН ": 219,
  "Гочоогийн ГАНБОЛД - МАН ": 165,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 86,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 99,
  "Чимиддоржийн ДАВААБАЯР - АН ": 83,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 34,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 39,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 10,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 58,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 11,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 6,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 3
 },
 {
  "id": 98,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 175,
  "Содномын ЧИНЗОРИГ - МАН ": 202,
  "Гочоогийн ГАНБОЛД - МАН ": 153,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 114,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 137,
  "Чимиддоржийн ДАВААБАЯР - АН ": 124,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 19,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 25,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 88,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 5,
  "Төмөрбаатарын ХАСБААТАР ": 5
 },
 {
  "id": 99,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 208,
  "Содномын ЧИНЗОРИГ - МАН ": 241,
  "Гочоогийн ГАНБОЛД - МАН ": 167,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 149,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 152,
  "Чимиддоржийн ДАВААБАЯР - АН ": 141,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 14,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 28,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 68,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 2
 },
 {
  "id": 100,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 206,
  "Содномын ЧИНЗОРИГ - МАН ": 241,
  "Гочоогийн ГАНБОЛД - МАН ": 176,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 169,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 182,
  "Чимиддоржийн ДАВААБАЯР - АН ": 161,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 26,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 29,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 10,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 70,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 3,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 4
 },
 {
  "id": 101,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 206,
  "Содномын ЧИНЗОРИГ - МАН ": 271,
  "Гочоогийн ГАНБОЛД - МАН ": 154,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 82,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 146,
  "Чимиддоржийн ДАВААБАЯР - АН ": 95,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 13,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 24,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 2,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 72,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 5,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 16
 },
 {
  "id": 102,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 297,
  "Содномын ЧИНЗОРИГ - МАН ": 383,
  "Гочоогийн ГАНБОЛД - МАН ": 212,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 133,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 234,
  "Чимиддоржийн ДАВААБАЯР - АН ": 162,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 20,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 32,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 7,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 139,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 6,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 1,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 4,
  "Төмөрбаатарын ХАСБААТАР ": 11
 },
 {
  "id": 103,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 242,
  "Содномын ЧИНЗОРИГ - МАН ": 321,
  "Гочоогийн ГАНБОЛД - МАН ": 207,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 120,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 189,
  "Чимиддоржийн ДАВААБАЯР - АН ": 129,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 19,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 28,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 8,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 81,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 3,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 2,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 5,
  "Төмөрбаатарын ХАСБААТАР ": 8
 },
 {
  "id": 104,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 332,
  "Содномын ЧИНЗОРИГ - МАН ": 454,
  "Гочоогийн ГАНБОЛД - МАН ": 252,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 157,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 260,
  "Чимиддоржийн ДАВААБАЯР - АН ": 164,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 21,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 70,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 93,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 7,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 9,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 14
 },
 {
  "id": 105,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 375,
  "Содномын ЧИНЗОРИГ - МАН ": 513,
  "Гочоогийн ГАНБОЛД - МАН ": 273,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 209,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 363,
  "Чимиддоржийн ДАВААБАЯР - АН ": 241,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 29,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 70,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 6,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 129,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 8,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 4,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 2,
  "Төмөрбаатарын ХАСБААТАР ": 7
 },
 {
  "id": 106,
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 192,
  "Содномын ЧИНЗОРИГ - МАН ": 269,
  "Гочоогийн ГАНБОЛД - МАН ": 148,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 96,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 163,
  "Чимиддоржийн ДАВААБАЯР - АН ": 135,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 10,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 21,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 4,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 51,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 2,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 0,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 3,
  "Төмөрбаатарын ХАСБААТАР ": 1
 },
 {
  "id": "total",
  "Дуламдоржийн ТОГТОХСҮРЭН - МАН ": 30020,
  "Содномын ЧИНЗОРИГ - МАН ": 32808,
  "Гочоогийн ГАНБОЛД - МАН ": 23560,
  "Оюунсайханы АЛТАНГЭРЭЛ - АН ": 17824,
  "Батхүүгийн ГАНХӨЛӨГ - АН ": 17277,
  "Чимиддоржийн ДАВААБАЯР - АН ": 17433,
  "Чагнаадоржийн ЦЭНДЭЭ - ТБЭ ": 2219,
  "Санжаасүрэнгийн ОРГОДОЛ - ТБЭ ": 4454,
  "Дамдинсүрэнгийн ХОРОЛДАМБА - ТБЭ ": 897,
  "Аюурзанын ОТГОНБААТАР - ШЭ ": 10077,
  "Дамдинжавын МӨНХБОЛД - ЗХЭЭ ": 1104,
  "Дуламдоржийн ХИШИГДЭЛГЭР - ХХН ": 382,
  "Дашбазарын ОТГОНЦЭЦЭГ - ЗОН ": 320,
  "Төмөрбаатарын ХАСБААТАР ": 1066
 }
]